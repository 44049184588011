/*colors*/
$transparent: transparent;

$white: #ffffff;
$black: #000000;

$grey: #F2F3F5;
$primary-grey: #EBEBEB;


$cyan: #26DCFB;
$primary-cyan: #00DCFD;
$secondary-cyan: #00E2BD;

$green: #00E881;
$primary-green: #25D366;
$green-3: #00E2C1;

$blue: #0B223E;
$primary-blue: #0C233D;
$secondary-blue: #0D223F;

$jotform-blue: #f3f3fe;

/*Fonts*/
$ai-font-poppins: 'Poppins', sans-serif;
