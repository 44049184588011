.ai-accordion{
  .ai-accordion-section-tabs{
    padding-left: 0;
    li{
      list-style: none;
      cursor: pointer;
      &.react-tabs__tab--selected{
        span{
          &:after{
            transform: scale(50);
          }
        }
      }
      span{
        display: block;
        font-weight: 600;
        font-size: 20px;
        padding: 1.5rem 2rem;
        border-radius: 15px 15px 0 0;
        border-bottom: 2px solid $cyan;
        overflow: hidden;
        position: relative;
        cursor: pointer;
        z-index: 2;
        color: $blue;

        &:after{
          content: '';
          width: 200%;
          height: 200%;
          border-radius: 100%;
          top: 100%;
          left: auto;
          right: -50%;
          bottom: -10px;
          margin: 0 auto;
          position: absolute;
          background-color: $cyan;
          transition: transform .5s ease;
          z-index: -1;
          pointer-events: none;
        }
        &:hover{
          &:after{
            transform: translateY(-60%);
          }
        }
      }
    }
    .ai-accordion-tabs-container{
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: flex-end;
      gap: 1rem;
      padding-bottom: 1rem;
      li{
        width: 100%;
      }
      @include max-xs{
        flex-direction: column !important;
      }
    }
  }
}

.ai-accordion-container{
  border: none;
  background: none;
  //ai-accordion-item
  .ai-accordion-item{
    background: none;
    border: none;
    // ai-accordion-title
    .ai-accordion-title{
      border: none;
      button{
        color: $primary-blue;
        font-weight: 600;
        background: none;
        border: none;
        font-size: 20px;
        padding: 2rem 2rem 2rem 3rem;
        position: relative;
        box-shadow: none;
        //border-bottom: .5px solid #969696;
        &:before{
          content: '';
          position: absolute;
          bottom: 0;
          right: auto;
          left: auto;
          width: calc(100% - 1.5rem - 3.5rem);
          height: 1px;
          background-color: rgba(#969696, 0.8);
          @include max-xs{
            width: 100%;
            left: 0;
            bottom: -1rem;
          }
        }
        &:after{
          width: 1.7rem;
          height: 1.7rem;
          background-size: 1.7rem;
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2z' fill='rgba(0,232,129,1)'/%3E%3C/svg%3E");
          margin-right: .2rem;
        }
        &[aria-expanded="true"]{
          color: $primary-blue;
          background: none;
          box-shadow: none;
          border: none;
          padding-bottom: 0;
          padding-left: 0;
          padding-right: 0;
          &:before{
            content: unset
          }
          &:after{
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M5 11h14v2H5z' fill='rgba(0,232,129,1)'/%3E%3C/svg%3E");
          }
        }
        &:focus{
          outline: none;
          box-shadow: none;
        }
      }
      @include max-xs{
        button{
          padding: 2rem 1.5rem 0 1.5rem !important;
          font-size: 16px;
        }
      }
    }
    // ai-accordion-body
    .ai-accordion-body{
      border: none;
      padding: 1rem 0rem;
      p{
        padding-right: 2rem;
        margin-bottom: 1rem !important;
      }
      button{
        margin-top: 1rem;
      }
      @include max-xs{
        padding: 1.5rem !important;
      }
    }
    &.active-accordion{
      background: $white;
      border-radius: 15px;
      padding: 3rem 1.5rem 3rem 3rem;
      margin: 3rem 0 1rem 0;
      @include max-xs{
        padding: 0 !important;
      }
      &:first-child{
        margin-top: 2rem;
      }
    }
    &:last-child{
      button{
        &:before{
          content: unset;
        }
      }
    }
  }
}

.faq-accordion{
  .ai-accordion-section-tabs{
    margin-right: 0 !important;
  }
}

#faq{
  scroll-margin-top: 0 !important;
}