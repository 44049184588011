.ai-article-intro{
  p{
    &:last-child{
      margin-bottom: 0 !important;
    }
  }
}

.container{
  &.ai-imported-articles{
    img{
      max-width: 100%;
    }
  }
  iframe{
    max-width: 100%;
  }
  &.ai-guide-posts{
    img{
      height: auto;
      width: 100%;
    }
  }
}