
.ai-form-check{
    display: block;
    min-height: 1.5rem;
    padding-left: 1.5em;
    margin-bottom: 0.125rem;
    
    .ai-checkbox {
        border: 2px solid $blue;
        width: 25px !important;
        height: 25px !important;
        border-radius: 5px;
        -moz-appearance:none;
        -webkit-appearance:none;
        -o-appearance:none;
        &:checked {
            box-shadow: none;
            background-color: $blue;
            border: none;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
        }
    }
}

.ai-checkbox [type=checkbox] {
    border: 2px solid $blue;
    width: 30px !important;
    height: 30px !important;
    border-radius: 5px;
}