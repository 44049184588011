/* ===== Vendor ==== */
@import 'vendor/ai-bootstrap';
@import "~slick-carousel/slick/slick.css"; 
@import "~slick-carousel/slick/slick-theme.css";

/* ===== Utils ==== */
@import "utils/ai-responsive";
@import 'utils/ai-variables';
@import 'utils/ai-commons';
@import 'utils/ai-fonts';
@import 'utils/ai-responsive-helpers';

/* ===== COMPONENTS ==== */
//Afilliation slider
@import 'components/abstract/ai-affiliation-slider';
//CTA
@import 'components/abstract/ai-cta';
//Reviews
@import 'components/abstract/ai-reviews';
//Header Search bar
@import 'components/header/ai-search-bar';
//Navigation
@import 'components/header/ai-navigation';
//card
@import 'components/ai-card';
//footer
@import 'components/footer/ai-footer';
//Buttons
@import 'components/ai-button';
//Tabs
@import 'components/ai-tabs';
//Link
@import 'components/abstract/ai-link';
//Page header
@import 'components/ai-page-header';
//Icon text cards
@import 'components/abstract/ai-icon-text';
//Price slider
@import 'components/abstract/ai-slider-bar';
// Link Card
@import 'components/abstract/ai-link-card';
//Post Card
@import 'components/cards/ai-post-card';
//info card
@import 'components/cards/ai-info-card';
//Options card
@import 'components/cards/ai-options-card';
//Testimonial card
@import 'components/cards/ai-testimonial-card';
//Site Category Card
@import "components/cards/ai-site-cat-card";
//Page Loader
@import "components/abstract/ai-page-transition";
//Carousel
@import "components/abstract/carousel";
//Archive
@import "components/archive/ai-archive";
//Accordion
@import "components/accordion/ai-accordion";
//Text Animationsn
@import "components/abstract/ai-text-animations";
//Video
@import "components/abstract/ai-video";
//AI BACKGROUND IMAGE
@import "components/abstract/ai-background-image";
//EI POPUP
@import "components/abstract/eipopup";
//form
@import 'components/abstract/ai-input';
@import 'components/abstract/ai-checkbox';
@import 'components/abstract/ai-textarea';
@import 'components/abstract/ai-range-slider';
@import "components/abstract/ai-general-forms";
//Maintenance Repair Card
@import "components/abstract/maintenance-repair-card";
@import "components/abstract/maintenance-repair-cta-card";

//WHATSAPP ICON
@import "components/abstract/ai-whatsapp-icon";

/* ===== Calculator Widget ==== */
@import "components/calculator-widget/ai-calculator-card";
@import "components/calculator-widget/ai-calculator-widget-wrapper";

/* ===== PAGES ==== */
@import 'pages/ai-home';
@import 'pages/ai-about';
@import "pages/ai-single-customer";
@import "pages/ai-single-product";
@import "pages/ai-promotions";
@import "pages/ai-single-promotion";
@import "pages/ai-thank-you";
@import "pages/ai-legal";
@import "pages/ai-contact";
@import "pages/ai-how-you-pay";
@import "pages/ai-search-results";
@import "pages/ai-start-saving";
@import "pages/ai-solar-batery-solutions";
@import "pages/ai-why-alumo";
@import "pages/ai-brand-pages";
@import "pages/ai-what-we-do";
@import "pages/ai-articles";
@import "pages/ai-single-article";
@import "pages/ai-commercial";
@import "pages/ai-maintenance-repairs";
@import "pages/ai-subscription";
@import "pages/ai-login";
/* ===== single posts ==== */
@import "single-posts/ai-single-posts-pagination";


.ai-image-wrapper {
    img {
        max-width: 100%;
        max-height: 100%;
        height: 100%;
    }
}

ul.benefits, ul.features {
    height: auto !important;
}


.ai-product-info-cards{

    @include min-lg{
        .col-md-3.ai-desktop {
            // width: ;
            flex-grow: 1;
            flex-shrink: 1;
            flex-basis: 0;
            text-align: center;
            /* border: 1px solid black; */
        }
    }
    
}

.capacity-notice {
    margin-bottom: 2rem;
}

.tooltip-data:empty {
    display: none !important;
}

.step-2-inputs {
    position: relative;
}

.ai-home-page-banner-image {
    text-align: center;
}

div#bookConsultationForm{
    padding-bottom: 8rem;
}

.bill-amount-notice{ 
    position: absolute;
    background: #0C233D;
    width: 250px;
    text-align: center;
    padding: 1rem;
    right: 50%;
    left: 17%;
    bottom: 10rem;
    border-radius: 5px;
    margin: auto;
    display: none;
    p {
        margin-bottom: 0 !important;
        font-size: 10px;
        color: #00E881;
        position: relative;
        z-index: 2;
    }
}

.slider-container:hover ~ .bill-amount-notice {
    display: block !important;
}

.slider-container:hover{
    cursor: pointer;
}

@media(max-width:760px){
    .communication-methods-checkboxes h6,
    .communication-methods-checkboxes .col-md-5 {
        width: 100%;
        margin-top: 1rem;
    }

    .get-quote .form-check .form-check-input {
        display: inline-block;
        height: 22px !important;
        width: 22px !important;
        margin-right: 0.5rem !important;
    }

    .get-quote .form-check label.form-check-label {
        width: 100%;
    }
}


@media(max-width:760px){
    .slider-container {
        max-width: 40%;
        margin: auto;
    }

    .bill-amount-notice {
        bottom: 23rem;
        z-index: 99;
    }
}

@media(min-width:800px) and (max-width:1300px){
    .ai-word-container {
        line-height: 1.2;
    }

    .ai-homepage-banner .ai-header-content-container h2 {
        font-size: 40px;
        line-height: 25px;
    }
}

.sizing-tool-faqs li#react-tabs-2,
.sizing-tool-faqs .ai-accordion-section-tabs,
.what-it-means .fan,
.what-it-means .coffee-machine{
    display: none !important;
}


.ai-start-saving-container .container .calculated-data .data-wrapper .accordion .accordion-body .what-it-means li:nth-child(9) {
    grid-column: initial;
}

.ai-start-saving-container .container .calculated-data .data-wrapper .accordion .accordion-body .what-it-means li:nth-child(10) {
    grid-column: initial;
}
img.get_a_quote_image {
    display: block;
    max-width: 180px;
    margin-top: 1.5rem;
}

.ai-start-saving-container .container .step-3 .ai-step-form {
    background-color: #ececed !important;
}


.ai-start-saving-container .container .step-3 .ai-step-form input{
    background-color:transparent !important;
    &:checked{
        background-color:$blue !important;  
    }
}


/**
New Page Additions
 */
//FAQ Items
.ai-no-tab-faq-item{
    padding-bottom: 2rem;
    h3{
        margin-bottom: 2rem;
    }
    img{
        margin-bottom: 2rem;
        margin-top: 4rem;
        border-radius: 15px;
        &.ai-mb-4{
            margin-bottom: 4rem !important;
        }
    }
    p.ai-mb-1{
        margin-bottom: 1rem !important;
    }
}

ul.check-list-column{
    flex-direction: column;
    li{
        width: 100%;
    }
}

.list-none{
    list-style: none;
    padding-left: 0;
    li{
        margin-bottom: 2rem;
    }
}

//Careers Page
.ai-embedded-container{
    // background-color: #f3f3fe;
    background-image: url(../images/start-saving/Alumo-Start-Saving-Background-Top.jpg);
    padding-block: 3rem;

    .ai-embed-inner-wrapper {
        display: flex;
        position: relative;
        height: 100%;
        max-width: 1300px;
        margin: 5rem auto;
        padding: 4rem 1rem 3rem 1rem;
        border-radius: 5px;
        overflow: hidden;
        background-color: #f3f3fe;
    }

    iframe{
        .supernova.isEmbeded{
            background-color: transparent !important;
            // background-image: url(../images/start-saving/Alumo-Start-Saving-Background-Top.jpg);
        }
    }
    & > div{
        height: 120px;
        // background-color: #0B223E;
        margin-bottom: .5rem;
        @media screen and (min-width: 768px) and (max-width:1199px){
            height: 82px;
        }
    }
}

.ai-rel-top-10{
    position: relative;
    top: -10rem;
}

.ai-section-sub-heading{
    h2, h3{
        font-family: Poppins,sans-serif !important;
        line-height: 1.2;
        font-size: 1.75rem;
        font-weight: 600 !important;
    }
}

.ai-img-border-radius{
    img{
        border-radius: 15px;
    }
}
.ai-p-mb-1{
    p{
        margin-bottom: 1rem !important;
    }
}
.ai-p-mt-2{
    p{
        margin-top: 2rem !important;
    }
}
.ai-p-24{
    p{
        font-size: 24px;
    }
}
.ai-list-ordered{
    ul{
        list-style: ordered;
    }
}

// ***************
// CUSTOM BLOG STYLING
// ***************
.ai-single-article {
    table {
        margin: 2rem 0;
        border: 1px solid $primary-blue;
        border-collapse: collapse;
        width: 100%;

        thead {
            background-color: $primary-blue;
            th {
                color: $white;
                padding: 1rem;
                border-right: 1px solid $white;
                border-collapse: collapse;

                &:last-child {
                    border-right: none;
                }
            }
        }

        tbody {
            tr {
                td {
                    padding: 0.5rem 0.8rem;
                    border: 1px solid $primary-blue;
                    border-collapse: collapse;
                }
            }
        }
    }
}
