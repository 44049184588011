.ai-content-row{
  padding-bottom: 4rem !important;
  h4{
    font-size: 20px;
    font-weight: 600;
    padding-bottom: .5rem;
  }
  p{
    margin-bottom: 0!important;
  }
}


.ai-legal-table{
  margin-bottom: 2rem;

  td{
    border: 1px solid $primary-blue;
    padding: 1rem;
    vertical-align: top;
  }
}

.ai-legal-content{
  p:empty{
    display: none;
  }
  h2{
    strong{
      font-weight: 500;
    }
    color: $primary-blue;
    font-weight: 500;
  }
  p{
    color: $primary-blue;
  }
}

//Fix
.ai-legal-content{
  .vc_row.wpb_row.vc_row-fluid:first-of-type{
    display: none;
  }
  p{
    margin-bottom: 0 !important;
  }
  b{
    font-weight: 500;
  }
  h1{
    font-size: 2rem;
    color: #0c233d;
  }
  ol{
    ol{
      li{
        list-style-type: circle;
      }
    }
  }
}