*{
    font-family: 'Poppins', sans-serif;
    -webkit-font-smoothing: antialiased;
}

.ai-regular {
    font-weight: 400;
}
.ai-medium {
    font-weight: 500;
}
.ai-semi-bold {
    font-weight: 600;
}
.ai-bold {
    font-weight: 700;
}
.ai-text-left{
    text-align: left !important;
}
.ai-em-semi-bold{
    em{
        font-style: normal;
        font-weight: 600;
    }
}
.ai-strong-semi-bold{
    strong{
        font-weight: 600;
    }
}