.ai-filter-items{
  padding-bottom: 0;
  padding-top: 0;
  max-width: fit-content;
  margin: 0 auto !important;
  .ai-filter-container{
    display: flex;
    // justify-content: space-evenly;
    justify-content: center;
    flex-direction: row;
    margin-bottom: 0;
    li{
      list-style: none;
      display: inline-block;
      margin: 0 20px;

      button{
        background: none;
        font-weight: 600;
        border-radius: 100px;
        border: none;
        box-shadow: none;
        border: 2px solid $cyan;
        color: $blue;
        padding: .7rem 2rem;
        position: relative;
        overflow: hidden;
        z-index: 2;
        @include max-xs{
          padding: .5rem 1.5rem
        }
        &:after{
          content: "";
          position: absolute;
          height: 200%;
          width: 200%;
          left: -50%;
          right: auto;
          margin: auto;
          bottom: -200%;
          background-color: $cyan;
          z-index: -1;
          transition: transform .5s ease;
          border-radius: 100%;
        }
        &:hover{
          &:after{
            transform: translateY(-60%);
          }
        }
        &.active{
          &:after{
            transform: scale(30);
          }
        }
      }
    }
  }
  @include max-lg{
    max-width: 100%;
    ul.ai-filter-container{
      display: flex;
      justify-content: space-evenly;
    }
  }
  @include max-xs{
    ul.ai-filter-container{
      flex-wrap: wrap;
      justify-content: flex-start;
      li{
        margin-bottom: 1rem;
        margin-right: .5rem;
      }
    }
  }
}
//ai-pagination
.ai-pagination{
  text-align: center;
  padding-bottom: 4rem;
  ul {
    list-style: none;
    margin-bottom: 4rem;
    li {
      cursor: pointer;
      display: inline-block;
      font-size: 20px;
      font-weight: 600;
      border-radius: 100%;
      width: 50px;
      height: 50px;
      padding: 0rem;
      line-height: 43px;
      padding-top: .1rem;
      &:hover{
        color: $cyan;
      }
      &.active{
        border: 2px solid $cyan !important;
      }
    }
  }
}

.post-card-container{
  display: grid !important;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 3rem;
  .col-md-4{
    width: 100%;
    .ai-post-card{
      width: 100%;
    }
  }
  @include max-lg{
    column-gap: 1rem;
  }
  @include max-md{
    grid-template-columns: repeat(2, 1fr);
    padding-top: 2rem !important;
  }
  @include max-xs{
    padding-top: 2rem !important;
    grid-template-columns: 1fr;
  }
}

.products-archive{
  .post-card-container{
    .col-md-4{
      margin-bottom: 2rem;
      .ai-post-card{
        margin-bottom: 0;
        p{
          margin-bottom: 0 !important;
        }
      }
    }
  }
}

.ai-sidebar-archive{
  .row{
    width: 100%;
    .ai-post-card{
      height: 100%;
      display: flex;
      flex-direction: column;
      margin-bottom: 0;
      .ai-post-card-img{
        height: 200px;
      }
      .ai-post-details{
        padding: 3rem 1.5rem 1rem 1.5rem;
        flex: 1;
        margin-bottom: 3rem;
      }
    }
    .ai-archive-sidebar{
      .mobile-title{
        display: none;
      }
      .desktop-title{
        display: flex
      }
    }
  }
  @include max-md{
    & > .row{
      flex-direction: column-reverse;
      .ai-archive-sidebar{
        margin-bottom: 4rem;
        &.collapsed{
          .mobile-title {
            margin-bottom: 0rem !important;
            padding-bottom: 0rem !important;
          }
          padding: 1.5rem 2rem 1rem 2rem;
          border-radius: 10px;
          .ai-sidebar-section{
            display: none;
          }
        }
        &.expanded{
          .mobile-title{
            margin-bottom: 2rem !important;
            button i{
              transform: rotate(180deg);
            }
          }
        }
        .mobile-title{
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          button{
            padding: 0;
            box-shadow: none;
            border: none;
            font-size: 0;
            i{
              display: block;
              color: $green;
              font-size: 24px;
            }

          }
        }
      }
    }
    .col-12{
      padding: 0;
    }
    .row{
      margin: 0;
      .col-md-4{
        padding: 0;
        .ai-post-card{
          width: 100%;
        }
      }
    }
  }
  .ai-archive-sidebar{
    background-color: $white;
    border-radius: 25px;
    padding: 2rem;
    height: fit-content;
    h4.ai-post-sidebar-title{
      padding-bottom: .5rem;
      font-weight: 600;
      font-size: 20px;
    }
    .ai-sidebar-section{
      margin-bottom: 2rem;
      &.ai-latest-posts{
        .ai-latest-post{
          display: flex;
          flex-direction: row;
          text-decoration: none;
          color: $blue;
          cursor: pointer;
          margin-bottom: 1.5rem;
          &:hover{
            color: $green;
          }
          .ai-latest-post-image{
            margin-right: 1rem;
            img{
              width: 80px;
              height: 80px;
              border-radius: 15px;
              object-fit: cover;
            }
          }
          .ai-latest-post-title{
            font-size: 14px;
            font-weight: 600;
            margin-bottom: 0 !important;
          }
        }
      }
      &.ai-clear-filter{
        margin-bottom: 0;
      }
    }

    .ai-sidebar-filter-item{
      background: none;
      box-shadow: none;
      border: none;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: .5rem;
      padding: 0;
      &:hover{
        color: $cyan;
        &:before{
          border-color: $cyan;
        }
      }
      &:before{
        content: "";
        height: 16px;
        width: 16px;
        border: 1px solid $blue;
        border-radius: 3px;
        margin-right: .5rem;
      }
      &.active-filter{
        &:hover{
          color: $blue;
          &:before{
            border-color: $blue;
          }
        }
        &:before{
          background-color: $blue;
        }
      }
    }
    .ai-sidebar-clear-filter{
      background: none;
      box-shadow: none;
      border: none;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: .5rem;
      color: $cyan;
      border-bottom: 1px solid $cyan;
      &:hover{
        color: $green;
        border-bottom-color: $green;
      }
    }
  }
}