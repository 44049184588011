.ai-tabs{
  .ai-tab-list{
    list-style: none;
    padding: 0;
    .ai-tab{
      display: inline-block;
      width: 250px;
      padding: 1rem 2rem;
      border-bottom: 2px solid $cyan;
      margin-right: .5rem;
      cursor: pointer;
      position: relative;
      overflow: hidden;
      border-radius: 15px 15px 0 0 ;
      z-index: 2;
      &:hover{
        &:after{
          transform: translateY(80%);
        }
      }
      &:after{
        content: '';
        width: 200%;
        height: 200%;
        background: $cyan;
        position: absolute;
        border-radius: 100%;
        left: -50%;
        z-index: -1;
        top: -200%;
        transition: transform .5s ease;
      }
      &.react-tabs__tab--selected{
        background: $cyan;
        .ai-tab-heading{
          color: $black;
        }
        .ai-tab-text{
          color: $black;
        }
      }
      .ai-tab-heading{
        display: block;
        color: $white;
      }
      .ai-tab-text{
        font-size: 12px;
        color: $white;
      }
    }
  }
  .ai-tab-content{
    background-color: $white;
    border-radius: 0 0 15px 15px;

    img{
      max-width: 100%;
      border-radius: 15px;
    }
    .ai-tab-content-title{
      font-size: 28px;
      color: $green;
      font-weight: 600;
      padding-bottom: 1rem;
    }
    .ai-tab-content-subtitle{
      font-size: 24px;
      font-weight: 600;
      padding-bottom: 1rem;
    }
    &.react-tabs__tab-panel--selected{
      padding: 2rem;
    }
  }
  &.ai-tabs-top{
    .ai-tab{
      &:after{
        left: -50%;
        top: 100%;
      }
      &:hover{
        &:after{
          transform: translateY(-80%);
        }
      }
    }
  }
  &.ai-how-you-pay-tabs{
    @include max-md{
      display: none;
    }
  }
  &.ai-benefits-tabs{
    @include max-md{
      display: none;
    }
  }
}

.ai-homepage-tabs{
  @include max-md{
    .ai-tab{
      padding: 1rem !important;
    }
  }
  @include max-xs{
    .ai-tab-list{
      display: inline-block !important;
      overflow: scroll;
      white-space: nowrap !important;
      width: 100%;
      scroll-behavior: smooth;
      li{
        white-space: pre-wrap !important;
        margin-right: 1rem !important;
      }
    }
  }
}

.ai-tabs-accordion{
  display: none;
  @include max-md{
    display: block;
    background: none;
    .accordion-item{
      background: none;
      .accordion-button{
        flex-direction: column;
        align-items: flex-start;
        background: none;
        border-radius: 10px 10px 0 0 !important;
        margin-bottom: .5rem;
        border-bottom: 1px solid $cyan;
        padding: 1rem;
        position: relative;
        span{
          max-width: calc(100% - 15px);
        }
        &::before{
          content: '' !important;
          width: 20px;
          height: 20px;
          background-image: url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 24 24%27 width=%2724%27 height=%2724%27%3E%3Cpath fill=%27none%27 d=%27M0 0h24v24H0z%27/%3E%3Cpath d=%27M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2z%27 fill=%27rgba%280,232,129,1%29%27/%3E%3C/svg%3E");
          position: absolute;
          right: .5rem;
          bottom:0;
          top: 0;
          margin: auto;
          z-index: 99;
        }
 
        &[aria-expanded="true"]{
          background: $cyan;
          .ai-tab-heading{
            color: $primary-blue;
            margin-bottom: .2rem;
          }
          .ai-tab-text{
            color: $primary-blue;
            font-size: 12px;
          }
          &::before{
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M5 11h14v2H5z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");;
          }
        }

        &:after{
          display: none;
        }
        .ai-tab-heading{
          font-weight: 600;
          color: $white;
          font-size: 18px;
          margin-bottom: 1rem;
        }
        .ai-tab-text{
          font-size: 14px;
          color: $white;
        }
      }
      .accordion-body{
        background: $white;
        padding: 2rem 1.5rem;
        h3{
          margin-bottom: 1rem;
          font-weight: 600;
        }
        h4{
          font-size: 16px;
        }
        p{
          font-size: 12px;
          line-height: 18px;
        }
        .ai-button{
          font-size: 14px;
          margin-bottom: 1rem;
          text-align: center;
          width: auto;
          padding-left: 30px;
          padding-right: 30px;
          margin-top: 1rem;
        }
        img{
          border-radius: 15px;
        }
      }
    }
  }
  @include max-xs{
    .accordion-body{
      .ai-button{
        width: 100% !important;
      }
    }
  }
  &.ai-benefits-tabs-mobile{
    .accordion-body{
      .row{
        &:last-child{
          p{
            margin-bottom: 0 !important;
          }
        }
      }
    }
  }
}
.ai-tabs-ul{
  ul{
    list-style: none;
    padding: 0;
    margin: auto;
    margin-top: 1rem;
    li{
      list-style: none;
      padding: 0;
      display: flex;
      flex-direction: row;
      margin-bottom: 1rem;
      text-align: left;
      &:before{
        margin-top: 0.1rem;
        content: "";
        display: block;
        height: 28px;
        min-width: 28px;
        margin-right: 0.5rem;
        background-repeat: no-repeat;
        vertical-align: top;
        background-image: url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 24 24%27 fill=%27rgba%280,220,253,1%29%27%3E%3Cpath d=%27M9.9997 15.1709L19.1921 5.97852L20.6063 7.39273L9.9997 17.9993L3.63574 11.6354L5.04996 10.2212L9.9997 15.1709Z%27%3E%3C/path%3E%3C/svg%3E");
      }
    }
  }
}