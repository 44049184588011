.ai-review-section {
    background-color: $green;
    clip-path: ellipse(80% 100% at 50% 100%);
    height: 700px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .slick-slider{
        padding-bottom: 2rem;
        .ai-review-card-container{
            width: 90% !important;
        }
        .slick-dots{
            li{
                button{
                    &:before{
                        opacity: 1;
                        font-size: 12px;
                        color: rgba($white, 0.5) !important;
                    }
                }
                &.slick-active{
                    button{
                        &:before {
                            color: $white !important;
                        }
                    }
                }
            }
        }
    }
}