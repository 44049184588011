.ai-page-header{
  text-align: center;
  padding-top: 8rem;
  padding-bottom: 4rem;
  background-size: cover;
  background-position: center center;
  min-height: 520px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .ai-header-content{
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .ai-page-title{
    height: auto;
    overflow: hidden;
  }
  .ai-page-subtitle{
    height: auto;
    overflow: hidden;
  }
  h2{
    color: $green;
    font-size: 24px;
    font-weight: 400;
    margin-bottom: 1.5rem;
    @include max-xs{
      font-size: 20px;
      margin-bottom: 1rem;
    }
    span.ai-word-container{
      overflow: hidden;
      display: inline-block;
      line-height: 26px;
      span{
        display: inline-block
      }
    }
  }
  h1{
    color: $white;
    font-size: 55px;
    font-weight: 600;
    max-width: 100%;
    margin: 0 auto;
    max-width: 880px;
    text-transform: capitalize;
    @include max-md{
      font-size: 38px;
      padding-left: 2rem;
      padding-right: 2rem;
      .ai-word-container{
        line-height: 52px !important;
      }
      @include max-xs{
        font-size: 37px;
      }
    }
    span.ai-word-container{
      overflow: hidden;
      display: inline-block;
      line-height: 70px;
      vertical-align: top;
      span{
        display: inline-block
      }
    }
  }
  // Header Type 3 - Header With Offset Image
  &.header-type-3{
    padding-top: 0;
    padding-bottom: 0;
    min-height: unset;
    .ai-header-3-container{
      padding-top: 8rem;
      padding-bottom: 8rem;
      .ai-page-title{
        h1{
          margin-bottom: 0;
        }
      }
    }
    .ai-header-image{
      position: relative;
      margin: 0 auto;
      padding: 0;
      top: -10rem;
      width: 100%;
      img{
        position: absolute;
        padding: 0;
        border-radius: 10px;
        width: 100%;
        max-width: 1082px;
        left: 0;
        right: 0;
        margin: 0 auto;
        @include max-lg{
          width: 90%
        }
        @include max-xs{
          width: 85%;
          height: 260px;
          object-fit: cover;
        }
      }
    }
  }
  &.header-type-2{
    text-align: left;
    position: relative;
    z-index: 2;
    &:after{
      content: "";
      background: rgba($blue, 0.9);
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
      z-index: -1;
    }
    .ai-header-content{
      max-width: 100%;
      width: 100%;
      margin: 0 auto;
      h1{
        font-size: 52px;
        color: $white;
        font-weight: 600;
        padding: 0;
        margin-bottom: 2rem;
        @include max-xs{
          font-size: 38px;
          line-height: 38px;
          .ai-word-container{
            line-height: 38px !important;
          }
        }
        span.ai-word-container{
          overflow: hidden;
          display: inline-block;
          line-height: 72px;
          span{
            display: inline-block
          }
        }
      }
      span{
        color: $white;
        display: inline-block;
        width: auto;
        &.ai-post-type{
          background: $primary-cyan;
          font-size: 14px;
          color: $blue;
          padding: .5rem 2rem;
          border-radius: 0 15px 15px 0;
          margin-bottom: 1rem;
          @include max-xs{
            border-radius: 0 5px 5px 0 !important;
            padding: .5rem 1rem !important;
          }
        }
      }
      .ai-project-details{
        display: inline-flex;
        align-items: center;
        overflow: hidden;
        width: auto;
        margin-right: 1rem;
        span{
          font-size: 14px;
        }
        i{
          font-size: 24px;
          color: $white;
          margin-right: .5rem;
        }
        span.ai-word-container{
          overflow: hidden;
          display: inline-block;
          line-height: 18px;
          vertical-align: top;
          span{
            display: inline-block
          }
        }
      }
    }
  }
  &.ai-single-page-header{

  }
  &.ai-page-header-title-full{
    h2{
      max-width: 100%;
    }
  }
}

.ai-divider{
  height: calc(483px - 12rem);
  @include max-lg{
    height: calc(418px - 12rem);
  }
  @include max-md{
    height: calc(322px - 12rem );
  }
}

.ai-page-header-parallax{
  height: 517px !important;
  //width: 100% !important;
  width: auto;
  @include fhd{
    height: unset !important;
    min-height: 100%;
  }
  @include max-md{
    height: 100% !important;
  }
}

.ai-post-share{
  margin: auto 0 auto auto;
  text-align: right;
  font-size: 14px;
  a{
    display: inline-block;
    margin-right: 1.5rem;
    text-decoration: none;
    cursor: pointer;
    &:last-child{
      margin-right: 0;
    }
    i{
      color: $green;
      font-size: 20px;
    }
    &:hover{
      i{
        color: $cyan;
      }
    }
  }

}

.ai-header-2-parallax{
  @include max-lg{
    img{
      bottom: 0 !important;
    }
  }
  @include max-xs{
    img{
      height: 100% !important;
      width: auto !important;
    }
  }
}

.ai-page-header-mw-900{
  h2{
    max-width: 900px;
  }
}

h1.ai-page-subtitle-h1{
  color: $white;
  font-size: 55px;
  font-weight: 600;
  max-width: 100%;
  margin: 0 auto;
  max-width: 880px;
  @include max-md{
    font-size: 38px;
    padding-left: 2rem;
    padding-right: 2rem;
    .ai-word-container{
      line-height: 52px !important;
    }
    @include max-xs{
      font-size: 37px;
    }
  }
  span.ai-word-container{
    overflow: hidden;
    display: inline-block;
    line-height: 70px;
    vertical-align: top;
    span{
      display: inline-block
    }
  }
}