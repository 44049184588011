.ai-single-customer-post-intro{
  .ai-project-details{
    list-style: none;
    display: flex;
    flex-direction: column;
    li{
      width: 100%;
      margin-bottom: 1rem;
      color: $blue;
      display: flex;
     span{
       display: inline-flex;
       width: 35%;
       font-weight: 600;
       color: $cyan;
     }
    }
  }
  .ai-project-image{
    img{
      border-radius: 15px;
      width: 83%;
      height: auto;
      @include max-xs{
        width: 100%;
      }
    }
  }
  @include max-xs{
    .row{
      padding-bottom: 2rem;
    }
  }
}

.ai-project-gallery-image{
  img{
    border-radius: 15px;
  }
}

//ICONS
.ai-project-products{
  .ai-project-intro-paragraph{
    padding-bottom: 2rem;
  }
  .project-products-row{
    gap: 50px;
    @include max-lg{
      flex-direction: row;
      gap: 0;
    }
  .ai-project-product{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @include max-lg{
      width: 50%;
      margin-bottom: 2rem;
    }
    @include max-xs{
      margin-bottom: 2rem;
      &:last-child{
        margin-bottom: 0;
      }
    }
      img{
        height: auto;
        width: 80%;
        margin-bottom: 1rem;
        @include max-lg{
          width: 100px;
        }
      }
      span.ai-project-product-title{
        text-align: center;
        font-weight: 600;
        font-size: 22px;
        color: $primary-blue;
      }
    }
  }
}