.ai-site-cat-card{
  display: block;
  width: 100%;
  height: auto;
  text-decoration: none;
  color: $blue;
  position: relative;
  overflow: hidden;
  z-index: 2;
  background: radial-gradient(circle, #26DCFB 50%, transparent 50%);
  background-repeat: no-repeat;
  background-size: 250% 250%;
  background-position-x: 160%;
  background-position-y: 200px;
  transition: background-position .6s ease;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;

  .ai-site-cat-card-image{
    height: 250px;
    width: 100%;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    background-size: cover;
    background-position: center center;
    @include max-sm{
      height: 185px;
    }
  }
  .ai-card-title{
    display: flex;
    border: 2px solid $cyan;
    border-top: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
    padding: 1.5rem 2rem;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    h3{
      font-size: 24px;
      font-weight: 600;
      margin-bottom: 0;
      color: $cyan;
      @include max-lg{
        font-size: 24px;
      }
    }
    i{
      font-size: 32px;
      display: block;
      transition: transform .5s ease;
      color: $cyan;
    }
  }
  &:hover{
    color: $blue;
    //&:after{
    //  transform: scale(120);
    //}
    background-position-x: center;
    background-position-y: center;
    .ai-card-title{
      h3{
        color: $white;
      }
      i{
        transform: translateX(15px);
        color: $white;
      }
    }
  }
}
