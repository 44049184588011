.ai-contact-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
  gap: 1rem;
  padding-top: 10rem;
  padding-bottom: 6rem;
  @include max-md{
    flex-direction: column;
  }
  .ai-contact-block {
    background-color: $white;
    border-radius: 15px;
    display: block;
    width: 90%;
    text-align: center;
    position: relative;
    padding-bottom: 3.5rem;
    padding-top: 5.5rem;
    @include max-md{
      width: 100%;
      margin-top: 3.5rem;
    }
    img {
      width: 110px;
      height: 110px;
      position: absolute;
      top: -3.5rem;
      left: 0;
      right: 0;
      margin: auto;
      display: block;
    }

    h3 {
      font-size: 16px;
      margin-bottom: 0;
    }

    a {
      text-decoration: none;
      color: $blue;
      font-size: 24px;
      font-weight: 600;
      &:hover{
        color: $green;
      }
    }
    &.ai-email{
      a{
        max-width: 80%;
      }
    }
    .ai-contact-social-icons{
      display: flex;
      justify-content: center;
      column-gap: 10px;
      row-gap: 5px;
      align-items: center;
      flex-wrap: wrap;
      width: 80%;
      margin: 0.5rem auto auto auto;
      .text-break{width: 100%}
      .ai-contact-social-icon{
        border: 2px solid $cyan;
        border-radius: 5px;
        height: 42px;
        width: 42px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        position: relative;
        overflow: hidden;
        z-index: 9;
        i{
          color: $blue;
          padding: 0 .5rem;
          font-size: 26px;
          vertical-align: middle;
          z-index: 9;
          font-weight: 400;
        }
        &:after{
          content:'';
          position: absolute;
          left: -1rem;
          bottom: -1rem;
          height: 10px;
          width: 10px;
          background: $cyan;
          transition: transform .5s ease;
          border-radius: 100%;
          z-index: 0;

        }
        &:hover{
          &:after{
            transform: scale(17);
          }
        }
      }
    }
  }

  .ai-whatsapp{
    .ai-whatsapp-link{
      a{
        padding-top: 1.5rem;
        color: $green;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        font-weight: 500;
        gap: 0.3rem;
        transition: color 0.5s ease;
        svg{
          fill: $green;
          path{
            &:last-child{
              transition: fill 0.5s ease;
              fill: $green;
            }
          }
        }
        &:hover{
          color: $cyan;
          svg{
            path{
              &:last-child{
                fill: $cyan;
              }
            }
          }
        }
      }
    }
  }
}


//Tabs
.ai-contact-tabs{
  @include max-md{
    display: none;
  }
}
.ai-contact-tab-wrapper{
  background: $white;
  border-radius: 0 15px 15px 0;
  padding: 3rem;

  .container{
    padding: 0;
    .row{
      margin-bottom: 2rem;
      &:last-child{
        margin-bottom: 0 !important;
      }
    }
  }
  .ai-contact-map-call, .ai-contact-map-location{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    .ai-contact-map-icon{
      margin-right: 1rem;
      i{
        font-size: 28px;
        width: 50px;
        height: 50px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        color: $green;
        border: 2px solid $green;
        border-radius: 5px;
      }
    }
    .ai-contact-map-details{
      a{
        text-decoration: none;
        &:hover{
          p{
            color: $green !important;
          }
        }
      }
      p{
        margin-bottom: 0 !important;
        font-size: 20px;
        font-weight: 600;
      }
      span{
        color: $green !important;
        font-weight: 600;
      }
    }
  }
  .ai-contact-map-container{
    iframe{
      width: 100%;
      border-radius: 15px;
    }
  }
}

.ai-accordion-section-tabs{
  margin-right: 1rem;
  .ai-contact-tab{
    margin-bottom: 1rem;
    border-right: 2px solid $cyan;
    padding: 1.5rem 1.5rem ;
    text-align: right;
    cursor: pointer;
    position: relative;
    z-index: 9;
    width: 80%;
    margin: auto 0 1rem auto;
    overflow: hidden;
    h2{
      font-size: 20px;
      margin-bottom: 0 !important;
    }
    &.react-tabs__tab--selected{
      background: $cyan;
      border-radius: 15px 0 0 15px;
      h2{
        color: $blue;
      }
    }
    &:after{
      content: '';
      position: absolute;
      height: 100%;
      width: 100%;
      max-width: 0;
      right: 0;
      top: 0;
      background-color: $cyan;
      z-index: -1;
      transition: max-width .5s ease;
      border-radius: 15px 0 0 15px;
    }
    &:hover{
      &:after {
        max-width: 100%;
      }
    }
  }
}

.ai-contact-tabs-mobile{
  display: none;
  @include max-md{
    display: block;
    .accordion-body{
      .row{
        flex-direction: column !important;
        .col-md-5, .col-md-7{
          width: 100% !important;
          margin-bottom: 1rem;
        }
        .ai-contact-map-details{
          .container{
          }
          a{
            p{
              font-size: 14px;
            }
          }
        }
      }
      .ai-contact-tab-wrapper{
        padding: 0;
      }
    }
  }
  @include max-xs{
    .ai-contact-tab-wrapper{
      .container{
        padding: 0 !important;
      }
    }
  }
}
