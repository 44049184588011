.articles-archive{
  padding-top: 6rem;
  .ai-filter-items{
    max-width: 65%;
  }
}

.articles-archive-header{
  h2{
    max-width: 1070px;
  }
}