.start-saving-settings{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .pick-solution,.electricity-bill{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    h6{
      font-weight: 600;
      line-height: 24px;
    }
  }
  .pick-solution{
    text-align: left;
    padding-right: 3rem;
    padding-left: 0;
    @include max-lg{
      padding-right: 1rem;
    }
    .ai-button-row{
      width: 280px;
      display: flex;
      justify-content: space-between;
      margin-left: 2rem;
      @include max-lg{
        margin-left: 0;
      }
      @include max-xs{
        width: 100%;
        justify-content: space-evenly;
        flex-direction: row !important;
      }
      .ai-button{
        padding-top: 8px;
        padding-bottom: 8px;

      }
    }
    h6{
      max-width: 160px;
      margin-bottom: 0;
      @include max-md{
        max-width: 100%;
        margin-bottom: 2rem;
      }
      @include max-xs{
        font-size: 12px;
        margin-bottom: 1rem;
      }
    }
    @include max-md{
      flex-direction: column;
    }
    @include max-xs{
      padding: 0;
      margin-bottom: 2rem;
      width: 100%;
    }
  }
  .electricity-bill{
    padding-left: 2rem;
    padding-right: 0;
    align-items: flex-start !important;
    .ai-range-slider{
      margin-top: 1rem;
      @include max-md{
        margin-bottom: 1rem;
      }
    }
    h6{
      width: 340px;
      text-align: left;
      @include max-xs{
        width: 100%;
        font-size: 12px;
        text-align: center;
        margin-bottom: 1rem;
      }
    }
    .rc-slider-tooltip-content{
      .rc-slider-tooltip-inner{
        background-color: $cyan !important;
      }
    }
    @include max-md{
      flex-direction: column;
    }
    @include max-xs{
      padding-left: 0;
    }
  }
  @include max-xs{
    flex-direction: column;
  }
}

//Buttons
button.start-saving-button{
  background: none;
  text-decoration: none;
  display: inline-block;
  width: auto;
  border-radius: 100px;
  border: 2px solid $cyan;
  font-family: $ai-font-poppins ;
  font-weight: 600;
  font-size: 16px;
  position: relative;
  overflow: hidden;
  z-index: 2;
  height: 100%;
  color: $primary-blue !important;
  background: linear-gradient(0deg, rgba(38,220,251,1) 50%, transparent 50%);
  background-repeat: no-repeat;
  background-size: 250% 250%;
  background-position-x: center;
  background-position-y: -100%;
  transition: background-position .6s ease;
  //&:after{
  //  content: '';
  //  position: absolute;
  //  width: 200%;
  //  height: 200%;
  //  bottom: -200%;
  //  left: -100%;
  //  background: $cyan;
  //  z-index: -1;
  //  transition: transform .5s ease;
  //}
  &:hover{
    background-position-y: 100%;
  }
  &.active{
    background-color: $cyan;
    color: $primary-blue !important;
  }
}
.ai-button-row{

  button.start-saving-button{
    padding: 10px 20px;
    &.active{
      color: $primary-blue;
    }
  }
}

.rc-slider-tooltip.rc-slider-tooltip-placement-top{
  padding-bottom: 3px !important;
}

//Homepage saving header
.power-calculator{
  .input-header{
    @include max-lg{
      .col-md-10{
        margin-left: 0;
        width: 100%;
      }
    }
  }
}

//homepage calculated values
.calculated-values{
  display: flex;
  align-items: flex-start;
  @include max-lg{
    justify-content: space-between;
  }
  .ai-calculated-info {
    .copy-text {
      width: 90%;
      padding-right: 2rem;
      img{
        width: 50px;
        height: 50px;
        margin-bottom: 1rem;
      }
    }
    @include max-lg{
      margin-top: 4rem;
      order: 3;
      width: 40%;
    }
    @include max-xs{
      width: 100%;
    }
  }
  .panels-image{
    display: flex;
    align-items: flex-end;
    @include max-lg{
      order: 1;
      width: 100%;
      margin-bottom: 4rem;
    }
    @include max-xs{
      width: 100%;
    }

    img{
      padding-right: 2rem;
    }
  }
  .appliance-usage{
    background: $green;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    box-shadow: 0 3px 14px rgba($black, 0.1);
    .padding-wrapper{
      padding: 2rem 2rem 2rem 2rem;
    }
    .ai-button{
      margin: 0 auto;
      margin-bottom: 2rem;
      border: 2px solid $white;
      font-size: 15px;
      padding-left: 18px !important;
      padding-right: 18px !important;
      background: radial-gradient($white 45%, transparent 45%);
      background-size: 275% 275%;
      background-position: 50% 50%;
      background-repeat: no-repeat;

      &:hover{
        background-position: 50% -50%;
      }
      &:after{
        //background: $white;
      }
    }
    h5{
      font-size: 16px;
      font-weight: 600;
      text-align: center;
      color: $blue;
      margin-bottom: 2rem;
    }
    h6{
      font-size: 12px;
      font-weight: 600;
      text-align: center;
      color: $blue;
      margin-bottom: 2rem;
    }
    .what-it-means{
      padding-left: 0;
      margin-bottom: 0;
      .ai-calculator-icon{
        margin-bottom: .5rem;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        .tooltip-data{
          top: unset;
        }
        svg{
          width: 60px;
          height: 60px;
          display: inline-block;
        }

        i{
          color: $blue;
          width: 60px;
          height: 60px;
          font-size: 32px;
          background:rgba($blue, 0.1);
          border-radius: 100%;
          padding: .5rem;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        span.count{
          position: absolute;
          color: $white;
          background: $blue;
          border-radius: 100%;
          height: 25px;
          width: 25px;
          font-size: 12px;
          display: flex;
          justify-content: center;
          align-items: center;
          top: -5px;
          right: 34px;
          @include max-lg{
            right: 69px;
          }
          @include max-md{
            right: 40px;
          }
        }
      }
      span.text{
        font-size: 11px
      }
      li{
        list-style: none;
        display: inline-flex;
        flex-direction: column;
        width: 50%;
        text-align: center;
        margin-bottom: 1rem;
      }
      .disabled{
        opacity: .4;
      }
      &.power-outage{
        height: 206px;
        overflow: visible;
        padding-top: 5px;
        li{
          width: 33%;
          span.count{
            right: 9px;
            @include max-lg{
              right: 35px;
            }
            @include max-md{
              right: 15px;
            }
          }
          //&.disabled{
          //  opacity: .4;
          //}
        }
      }
    }
    @include max-lg{
      order: 2;
      width: 50%;
    }
    @include max-xs{
      width: 100%;
    }
  }
}

.calculated-values{
  .ai-icon-text{
    i{
      width: 65px;
      height: 65px;
      background: $blue;
      margin-bottom: 1.5rem;
    }
    h3{
      font-size: 20px;
      font-weight: 600;
      margin-bottom: .5rem;
    }
  }
}

//Steps
.ai-start-saving-container{
  min-height: 100vh;
  display: flex;
  align-items: center;
  background-attachment: fixed;
  background-size: initial;
  .container{
    height: 100%;
    .step-navigation{
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 2rem;
    }
    .link-back{
      border: 2px solid $cyan;
      border-radius: 5px;
      height: 50px;
      width: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;
      position: relative;
      overflow: hidden;
      cursor: pointer;
      background: rgb(0,212,255);
      background: radial-gradient(circle, #26DCFB 50%, transparent 50%);
      background-repeat: no-repeat;
      background-size: 250% 250%;
      background-position-x: -90px;
      background-position-y: 50px;
      transition: background-position .6s ease;
      &:hover{
        background-position-x: -30px;
        background-position-y: -30px;
      }
      i{
        font-size: 28px;
        color: $cyan;
        z-index: 3;
        transition-delay: .2s;
      }
      //&:after{
      //  content: '';
      //  height: 10px;
      //  width: 10px;
      //  background-color: $cyan;
      //  left: -10px;
      //  bottom: -10px;
      //  border-radius: 100%;
      //  position: absolute;
      //  z-index: 1;
      //  transition: transform .5s ease;
      //}
      &:hover{
        i{
          color: $blue;
        }
        //&:after {
        //  transform: scale(50);
        //}

      }
    }
    .link-forward{
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;
      color: $green;
      cursor: pointer;
      font-size: 18px;
      i{
        font-size: 20px;
        margin-left: .5rem;
        transition: transform .5s ease;
      }
      &:hover{
        color: $cyan;
        span{
        }
        i{
          transform: translateX(5px);
        }
      }
      &.step-4{
        color: $white;
        span{
          text-decoration: underline;
        }
        &:hover{
          color: $cyan;
        }
      }
    }
    .step-footer{
      a{
        display: block;
        text-align: center;
        color: $cyan;
        padding-bottom: 4rem;
        margin-bottom: 0;
        font-size: 14px;
        &:hover{
          color: $green;
        }
      }
      hr{
        color: $cyan;
        opacity: 1;
        margin: 0;
      }
      h3{
        margin-top: 6rem;
        font-weight: 600;
        font-size: 24px;
        margin-bottom: 0;
        padding-bottom: 2rem;
        @include max-md{
          margin-left: 2rem;
          margin-right: 2rem;
        }
      }
      p{
        font-size: 16px;
        margin-bottom: 4rem !important;
      }
    }
    .step-1{
      .step-container{
        background-color: $white;
        border-radius: 15px;
        padding: 3rem 5rem;
        text-align: center;
        margin-bottom: -120px;
        @include max-md{
          padding: 1rem;
        }
        h2{
          font-weight: 600;
          font-size: 34px;
          text-align: center;
          //margin-bottom: 2rem;
          max-width: 60%;
          margin: 0 auto 2rem auto;
          @include max-md{
            font-size: 24px;
            max-width: 100%;
          }
        }
        p{
          font-size: 18px;
          text-align: center;
          @include max-md{
            font-size: 16px;
          }
        }
        .rc-slider{
          max-width: 75%;
          margin: 0 auto;
          margin-top: 5rem;
        }
        .ai-button{
          margin-top: 4rem;
        }
        button.ai-enquiry-type-button{
          margin: 0 0.5rem;
          &.active{
            background: $cyan;
          }
          @include max-sm{
            margin-bottom: 1rem;
            &:last-of-type{
              margin-bottom: 0;
            }
          }
        }
        @include max-sm{
          .rc-slider-mark-text{
            margin: 0 !important;
          }
        }
      }

      //NEW RESIDENTIAL BUTTON ADDITIONS
      &.residential{
        h2{
          margin-bottom: 2rem;
        }
        .ai-button-row{
          margin-bottom: 3rem;
        }
      }
    }
    .step-2{
      margin-top: calc(120px + 6rem);
      @include max-sm{
        margin-top: 120px;
      }
      .step-2-inputs{
        max-width: 100%;
        margin: 0 auto;
        h4{
          font-size: 20px;
          font-weight: 600;
          color: $white;
          text-align: center;
          margin-bottom: 1rem;
          }
        }
        .rc-slider{
          max-width: 400px;
          margin: 0 auto;
          .rc-slider-track{
            background-color: $cyan !important;
          }
          .rc-slider-handle{
            background-color: $cyan !important;
          }
          .rc-slider-rail{
            background: $primary-grey !important;
            opacity: 1 !important;
          }
          .rc-slider-mark-text{
            color: $white !important;
          }
        }
      @include max-sm{
        .slider-container{
          max-width: 70%;
          .rc-slider-mark-text{
            margin-left: 0 !important;
          }
        }

        .step-navigation{
          .text-link{
            justify-content: flex-end;
            span{
              width: 60%;
            }
          }
        }
      }
    }
    .step-3 {
      margin-top: calc(120px + 6rem);
      .ai-step-form{
        background-color: $white;
        padding-top: 4rem;
        padding-bottom: 4rem;
        border-radius: 5px;
        @include max-xs{
          padding: 1rem;
        }
        h3{
          text-align: center;
          font-size: 34px;
          font-weight: 600;
          margin-bottom: 1rem;
          color: $primary-blue;
          @include max-xs{
            font-size: 24px !important;
          }
        }
        p{
          text-align: center;
          font-size: 16px;
          color: $primary-blue;
        }
      }
      button{
        margin: 0 auto;
        display: flex;
      }
      @include max-sm{
        margin-top: calc(120px + 0rem);
        .step-navigation{
          padding-left: 0 !important;
          padding-right: 0 !important;
        }
      }
    }
    .step-4.thank-you{
      background-color: $white;
      text-align: center;
      border-radius: 15px;
      .content{
        padding: 4rem 6rem;
        @include max-xs{
          padding: 4rem 1rem;
        }
      }
      h3{
        font-size: 34px;
        font-weight: 600;
      }
      p{
        font-size: 18px;
      }
      .ai-button-row{
        width: 100%;
        display: flex;
        justify-content: space-evenly;
      }
    }
    .sticky-info{
      position: fixed;
      top: 81px;
      width: 100%;
      left: 0px;
      right: 0;
      margin: auto;
      z-index: 4;
      background-color: $green;
      display: flex;
      justify-content: center;
      align-items: center;
      li{
        margin-bottom: .5rem;
        &:last-child{
          margin-bottom: 0;
        }
      }
      .row{

      }
      .info-item{
        background: $green;
        padding-top: 1rem !important;
        padding-bottom: 1rem !important;
        .item-wrapper{
          max-width: 88%;
          margin: 0 auto;
        }
        h4{
          text-transform: capitalize;
          color: $white;
        }
        ul.costs{
          padding: 0;
          margin-bottom: 0;

          li{
            color: $primary-blue;
            list-style-type: none;
            font-size: 12px;
            display: flex;
            justify-content: space-between;
            b{
              span{
                text-decoration: underline;
                text-decoration-color: $primary-blue;
                cursor: pointer;
              }
            }
          }
        }
      }
      @include max-lg{
        display: none !important;
      }
    }
    .calculated-data{
      .data-wrapper{
        background: $white;
        border-radius: 15px;
        max-width: 88%;
        margin: 0 auto;
        padding: 1rem;
        @include max-lg{
          max-width: 95%;
        }
        .header{
          text-align: center;

          .ai-content-header {
            width: 100%;

            &.ai-rent-option {
              display: inline-flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
              width: 100%;
            }
          }

          .ai-header-image-limitted-offer {
            display: inline-block;
            transform: translate(1.6vh, 0.95vw);
            height: 10vh;
            min-width: 4vw;
            margin: 0;

            @include max-lg {
              transform: translate(1.5vh, 1.3vw);
            }
            @include xxs{
              transform: translate(0.78rem, 0.2rem);
            }
            @include iphone8{
              // transform: translate(0.8rem, -1.1rem);
              transform: translate(0.8rem, -0.499rem);
            }
            @include iphone4{
              transform: translate(0.8rem, -1.1rem);
              // transform: translate(0.8rem, -0.499rem);
            }
            @include iphone8-landscape {
              transform: translate(3.5vh, -4vw);
            }
            @include max-xs-landscape {
              transform: translate(3.1vh, -3vw);
            }
            svg {
              transform: scale(2);
              margin: 0;
            }
          }
          .ai-header-image-initiation {
            display: inline-block;
            transform: translate(0.9vh, 0.5vw);
            height: 10vh;
            margin: 0;

            svg {
              transform: scale(1.3);
              margin: 0;
            }
            @include iphone8{
              transform: translate(0.45rem, -1.1rem);
            }
            @include max-xs-landscape {
              transform: translate(2vh, 0.5vw);
            }

          }

          svg{
            width: 60px;
            height: 60px;
            margin-bottom: 1rem;
            display: inline-block;
          }
          i{
            display: inline-flex;
            align-items: center;
            justify-content: center;
            height: 60px;
            width: 60px;
            color: $blue;
            border: 2px solid $cyan;
            padding: 1rem;
            border-radius: 100%;
            font-size: 32px;
            margin-bottom: 1rem;
          }
          h4{
            font-weight: 600;
            text-transform: capitalize;
            color: $primary-blue;
          }
          p{
            font-size: 14px;
            margin-bottom: 0 !important;
            color: $primary-blue;
          }
          small {
            font-size: 0.6rem;
          }
          hr{
            color: $cyan;
            opacity: 1;
          }
          .estimation{
            margin-bottom: 1.5rem;
            em.ai-tooltip-text{
              text-decoration-color:$primary-blue ;
            }
            h4{
              font-size: 28px;
              margin-bottom: 0 !important;
              color: $primary-blue;
              a{
                color: $primary-blue;
                &:hover{
                  color: $cyan;
                }
              }
            }
            span{
              font-size: 12px;
            }
            p{
              font-size: 12px;
              font-weight: 600;
              color: $blue;
            }
            .tnc{
              display: block;
              color: $primary-blue;
              font-size: 10px;
              font-style: italic;
            }
          }
          .costs{
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            h6{
              display: block;
              width: 100%;
              color: $primary-blue;
              font-size: 12px;
              font-weight: 600;
              margin-bottom: 0 ;
            }
            h5{
              display: block;
              width: 100%;
              color: $primary-blue;
              font-size: 26px;
              font-weight: 600;
              margin-bottom: 1.5rem;
            }
            .cost-blocks{
              display: flex;
              flex-direction: row;
              flex-wrap: wrap;
              gap: 5px;
              margin-bottom: 1rem;
              width: 100%;
              .rental-period-savings{
                span{
                  padding: 0
                }
              }
              .purchase-amount{
                span{
                  padding: 0;
                }
              }
              .rental-period-details{
                h5{
                  font-size: 24px;
                }
              }
              .buy-out-fee{
                span{
                  padding: 0 1rem
                }
              }
              & > div{
                width: 49%;
                background: rgba($cyan, 0.2);
                border-radius: 5px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: .7rem 1rem;
                &.savings-background{
                  background-image: linear-gradient(45deg, $cyan, 30%, $green);
                  color: #0C233D;
                  padding-top: 0;
                  .ai-savings-icon{
                    margin-bottom: .5rem;
                    img{
                      height: 36px;
                    }
                  }
                  span{
                    padding: 2px 6px !important;
                    border: 1px solid #0C233D;
                    font-weight: 600;
                    // color: $white !important;
                    border-radius: 50px;
                  }
                }
                span{
                  font-size: 10px;
                  font-weight: 500;
                  margin-bottom: 5px;
                  padding: 0 .5rem;
                  @include max-lg{
                    padding: 0 5px !important;
                  }
                }
                h5{
                  font-size: 24px;
                  font-weight: 600;
                  margin-bottom: 0;
                  @include max-lg{
                    font-size: 20px;
                  }
                }
              }
            }

          }
        }
        .ai-button{
          width: 100%;
          text-align: center;
          font-weight: 600;
        }
        .accordion{
          border: none;
          padding-bottom: 1rem;
          margin-top: 2rem;
          .accordion-item{
            border: none;
            margin-bottom: 1rem;
            &:last-child{
              margin-bottom: 0;
            }
            .accordion-header{
              overflow: hidden;
              position: relative;
              @include max-lg{
                padding: 0 !important;
              }
              button{
                background: $primary-blue;
                color: $white;
                font-weight: 600;
                font-size: 14px;
                padding: .5rem 1rem;
                border-radius: 5px;
                overflow: hidden;
                position: relative;
                @include max-lg{
                  font-size: 12px !important;
                }
                &.collapsed{
                  &:before{
                    content: "";
                    height: 10px;
                    width: 10px;
                    position: absolute;
                    background: $cyan;
                    left: -15px;
                    right: auto;
                    bottom: -10px;
                    border-radius: 100%;
                    z-index: -1;
                    transition: transform 1s ease;
                    margin: auto;
                  }
                  &:after{
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
                  }
                }
                &:hover{
                  &:before{
                    transform: scale(75);
                  }
                }
                &:focus{
                  outline: none;
                  box-shadow: none;
                }
                &:after{
                  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M5 11h14v2H5z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
                }

              }
              &.show{

              }

            }
          }
          .accordion-body{
            padding: 1rem;
            ul{
              padding: 0;
              li{
                list-style: none;
              }
            }
            .what-it-means{
              display: grid;
              grid-template-columns: repeat(4, 1fr);
              grid-gap: 22px;
              @include max-lg{
                grid-gap: 10px !important;
              }
              li{
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center;
                color: $primary-blue;
                span.text{
                  font-size: 12px;
                }
                &:nth-child(9){
                  grid-column: 2;
                }
                &:nth-child(10){
                  grid-column: 3;
                }
                &.disabled{
                  opacity: .4;
                }
              }
              .power-icon-text{
                position: relative;
                span.count{
                  position: absolute;
                  color: $blue;
                  background: $cyan;
                  border-radius: 100%;
                  height: 25px;
                  width: 25px;
                  font-size: 12px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  top: -6px;
                  right: -2px;
                  font-weight: 600;
                }
                svg{
                  width: 60px;
                  height: 60px;
                  display: inline-block;
                }
                i{
                  display: inline-flex;
                  align-items: center;
                  justify-content: center;
                  height: 60px;
                  width: 60px;
                  color: $primary-blue;
                  background: rgba($cyan, 0.2);
                  padding: 1rem;
                  border-radius: 100%;
                  font-size: 30px;
                  margin-bottom: .2rem;
                }
              }
            }
            .benefits{
              margin-bottom: 0;
              //height: 120px;
              overflow: scroll;
              //padding-bottom: 1rem;
              overflow: hidden;
              li{
                display: flex;
                font-size: 14px;
                align-items: center;
                .heading{
                  a{
                    text-decoration-color: $blue;
                    text-decoration: underline;
                    color: $blue;
                    &:hover{
                      color: $cyan !important;
                      text-decoration-color: $cyan;
                    }
                  }
                }
                i{
                  color: $cyan;
                  margin-right: .5rem;
                  font-size: 18px;
                }
              }
            }
            .features{
              margin-bottom: 0;
              overflow: hidden;
              li{
                font-size: 14px;
                color: $primary-blue;
                margin-bottom: 1rem;
                &:last-child{
                  margin-bottom: 0;
                }
                .heading{
                  font-weight: 600;
                  color: $primary-blue;
                  font-size: 14px;
                }
                display: flex;
                flex-direction: column;
              }
            }
            .system-size{
              margin-bottom: 0;
              height: 104px;
              li{
                display: flex;
                justify-content: space-between;
                margin-bottom: 1rem;
                a{
                  color: $blue;
                  &:hover{
                    color: $cyan;
                  }
                }
                &:last-child{
                  margin-bottom: 0;
                }
              }
            }
          }

        }
        .card-button-bottom{
          padding-top: 1rem;
          border-top: 1px solid $cyan;
        }
        .extra-info{
          border-bottom: 1px solid $cyan;
          margin-bottom: 1rem;
        }
      }
      &.commercial{
        padding-left: 0;
        padding-right: 0;
        border-radius: 15px;
        height: fit-content;
        .ai-commercial-ss{
          .data-wrapper{
            width: 100%;
            max-width: 100%;
            display: flex;
            justify-content: space-between;
            padding: 0.8rem;
            gap: 3rem;
            .header{
              width: 55%;
              .header-divider{
                margin-left: 2rem;
                margin-right: 2rem;
              }
              .ai-content-header{
                svg{
                  border: 2px solid $cyan;
                  border-radius: 100%;
                  padding: .7rem;
                }
                &.ai-rent-option{
                  justify-content: center;
                }
              }
              .estimation{
                margin-bottom: 1rem;
                h4{
                  font-size: 32px;
                  padding-bottom: .5rem;
                  &.ai-ss-contact-link{
                    text-transform: none  !important;
                  }
                  &:last-of-type{
                    padding-bottom: 0;
                  }
                }
              }
              .cost-blocks{
                justify-content: space-between;
                margin-bottom: 0;
                .cost-block-center{
                  margin: 0 auto;
                }
                & > div{
                  width: 48%;
                  padding-left: 0.5rem !important;
                  padding-right: 0.5rem !important;
                  height: 90px;
                  margin-bottom: .3rem;
                }
                .utility-independence, .battery{
                  margin-bottom: 0;
                  @include max-sm{
                    margin-bottom: .5rem;
                  }
                }
              }
            }
            .extra-info{
              width: 45%;
              border-bottom: none;
              .accordion{
                margin-top: 0;
                .accordion-item{
                  margin-bottom: .5rem;
                  .accordion-header{
                    button{
                      padding: 0.4rem 1rem;
                    }
                  }
                  .accordion-body{
                    padding-bottom: 0;
                    padding-top: 0.8rem;
                  }
                }

                li{
                  align-items: flex-start;
                  margin-bottom: .5rem;
                  i{
                    line-height: 20px;
                  }
                }
                .system-size{
                  height: auto;
                  li{
                    margin-bottom: .5rem;
                    font-size: 14px;
                  }
                }
              }
            }
          }
          @include max-sm{
            .data-wrapper{
              flex-direction: column;
              justify-content: flex-start;
              gap: 0;
              .header{
                width: 100% !important;
              }
              .extra-info{
                width: 100% !important;
              }
            }
          }
        }
      }
    }
    @include max-sm{
      .ai-reviews-container{
        margin-top: 2rem !important;
        padding-left: 0;
        padding-right: 0;
        h4{
          padding: 0;
        }
        .start-saving-testimonial{
          .container{
            padding-left: 0 !important;
            padding-right: 0 !important;

          }
        }
      }
    }
  }
}

span.tooltip-text{
  text-decoration: underline;
  text-decoration-color: $blue;
  cursor: pointer;
}

//Step Options
.ai-step-options{
  max-width: 96%;
  margin: 0 auto !important;

  @include xxs {
    padding: 3rem 1rem 0 1.2rem;
  }
  @include md-to-lg{
    flex-direction: row !important;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    .pick-solution{
      margin-left: 0 !important;
    }
  }

  .col-md-4{
    .pick-solution{
      justify-content: flex-start;
    }
  }

  .col-md-4{
    .pick-solution{
      justify-content: flex-start;
    }
  }

  .pick-solution-rental-period{
    display: flex;
    .pick-solution{

      h6{
        margin-bottom: 1em;
      }
      width: fit-content;
      margin: auto 0 auto auto;
      display: inline-block;
      button{
        margin-left: 0.5em;
        margin-right: 0.5em;
        @include max_xs{
          margin-bottom: 1em;
        }
        &:first-of-type{
          margin-left: 0;
        }
        &:last-of-type{
          margin-right: 0;
        }
      }

      @include xxs {
        margin: auto auto auto 0;
      }
    }
    @include md-to-lg{
      margin-top: 1rem;
    }
  }
  .pick-solution {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;
    h6 {
      font-weight: 600;
      margin-bottom: 0;
      margin-right: .5rem;
      width: 100%;
    }
    .ai-button-row{
      display: flex;
      gap: 1rem;
    }
    .start-saving-button {
      color: $white !important;
      padding: 10px 20px;
      font-size: 16px;
      width: auto !important;
      height: auto !important;

      &.active{
        color: $primary-blue !important;
      }
    }
    &.disabled{
      pointer-events: none;
      opacity: 0.5;
    }
  }
  @include max-lg{
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    .pick-solution{
      .ai-button-row{
        flex-direction: row !important;
      }
    }
    .col-md-7{
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin-bottom: 4rem;
      .pick-solution{
        justify-content: center;
        flex-wrap: wrap;
        text-align: center;
        h6{
          width: 100%;
          text-align: center;
          margin-bottom: 1rem;
        }
      }
    }
    .col-md-5{
      .pick-solution{
        flex-direction: column;
        h6{
          margin-bottom: 1rem;
        }
      }
    }
  }
  @include max-xs{
    & > div{
      padding-bottom: 2rem;
      .pick-solution{
        padding-left: 0;
        flex-direction: row !important;
      }
    }
  }

}
//Tooltip Text

.user-details {
  hr{
  }
  .row {
    padding-bottom: 1rem;
    //gap: 60px;
    column-gap: 60px;
    & > div{
      width: calc(35% - 60px);
      @include max-sm{
        width: 100%;
      }
      @include md-to-lg{
        width: calc(43%);
      }
    }
    &.ai-pb-clr{
    }
  }
  .row-gap{
    gap: 60px;
  }
  .form-group.form-inline{
    display: flex;
    align-items: center;
    @include max-xs{
      flex-wrap: wrap;
    }
    h6{
      margin-bottom: 0;
      color: $primary-blue;
      margin-right: 1rem;
      @include max-xs{
        padding-bottom: .5rem;
      }
    }
    .form-check{
      align-items: center;
      margin-bottom: 0;
      height: 25px;
      label{
        padding-top: .2rem;
      }
      input[type="radio"]{
        display: inline-block;
        float: none;
        margin-right: .4rem;
        cursor: pointer;
        & + label{
          margin-right: 1rem;
        }
      }
      &:last-child{
        margin-right: 0;
        input[type="radio"]{
          margin-right: .4rem;
          & + label{
            margin-right: 0rem;
          }
        }
      }
      display: flex;
      justify-content: center;
    }
  }
  @include max-xs{
    .row-gap{
      flex-direction: column;
      gap: 20px !important;
    }
  }
}

.selected-package{
  padding-top: 1rem;
  h6.current-bill-heading{
    margin-bottom: 1rem !important;
  }
  .current-bill{
    text-align: left !important;
    margin-bottom: 1rem !important;
  }
  .solution-preference{
    padding-bottom: 0;
    margin-bottom: 1.2rem;
    &.disabled{
      label{
        opacity: 1;
        pointer-events: none;
      }
    }
    &.field-na{
      opacity: 0.5;
      pointer-events: none;
    }

    @include max-xs{
      .ai-form-flex{
        flex-wrap: wrap !important;
      }
    }
    h6{
      color: $primary-blue;
      margin-bottom: 0;
    }
    .form-check{
      padding-left: 0;
      @include max-xs{
        display: inline-block;
        width: 44%;
        label{
          max-width: 100%;
          font-size: 12px ;
        }
      }
    }
    input[type="radio"]{
      visibility: hidden;
      &:checked + label{
        background-color: $cyan;
      }
    }
    label{
        margin-top: 1.2rem;
        border: 2px solid $cyan;
        padding: 10px 0px;
        width: 180px !important;
        text-align: center;
        border-radius: 5px;
        font-weight: 600;
        color: $primary-blue;
        // cursor: pointer;
        position: relative;
        overflow: hidden;

        z-index: 2;
      cursor: pointer;
      &:after{
        content: '';
        width: 200%;
        height: 160%;
        background: $cyan;
        position: absolute;
        left: -50%;
        bottom: -200%;
        border-radius: 100%;
        z-index: -1;
        transition: transform .5s ease;
      }
      &:hover{
        &:after{
          transform: translateY(-100%);
        }
      }
    }
    @include md-to-lg{
      &.solution-option{
        .form-check-inline{
          margin-right: .5rem;
          label{
            width: 135px !important;
          }
        }
      }
    }
    @include max-xs{
      &.solution-option{
        .form-check-inline{
          label{
            width: 135px !important;
          }
        }
      }
      h6{
        margin-bottom: 1rem;
      }
      .form-check-inline{

        margin-right: .5rem;
        width: fit-content;
        label{
          margin-top: 0;
        }
      }
      &.preferred-package{
        .form-check-inline{
          width: 47%;
        }
      }
    }
    @include md-to-lg{
      &.preferred-package{
        .form-check-inline{
          margin-right: .5rem;
        }
      }
    }
    &.solution-type{
      label {
        width: 120px !important;
      }
    }
    &.solution-option {
      label {
        width: 150px !important;
      }
    }
    &.rental-period{
      label{
        width: 103px !important;
      }
    }

    .pick-solution {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      margin-top: 1rem;

      button {
        margin: 0 0.8rem 0 0;
        border-radius: 5px;
        width: 140px;
        padding: 10px 0;
        display: inline-flex;
        flex-direction: row;
        justify-content: center;
      }

      &.disabled {
        opacity: .5;
        pointer-events: none;
      }
    }
    &.operating_time{
      label{
        width: 150px !important;
      }
    }
  }
}

.terms-conditions{
  margin-top: 1rem !important;
  margin-bottom: 1rem;
  input[type="checkbox"]{
    margin-right: .5rem;
  }
  label{
    color: $primary-blue;
    padding-top: .2rem;
    a{
      text-decoration-color: $primary-blue;
      color: $primary-blue;
      &:hover{
        color: $cyan;
        text-decoration-color: $cyan;
      }
    }
  }
  @include max-xs{
    .form-check{
      padding-left: 0;
      display: flex;
      justify-content: flex-start;
      .form-check-input{
        display: inline-block;
        height: 20px;
        width: 30px;
        margin-right: .5rem !important;
      }
      label{
        padding-top:0;
      }
    }
    input{
      margin: 0 !important;
    }
  }
}

.get-quote{
  margin-bottom: 3rem !important;
}

select {
  background-image: url("../../images/start-saving/icons/Alumo-Dropdown-Arrow-2.svg");
  background-position: center;
  -webkit-appearance: none !important;
  background-repeat: no-repeat;
  background-position: 95% center;
  background-color: transparent !important;

}

.form-control{
  padding: 0.375rem 0rem !important;
  text-indent: 18px !important;
}

.get-quote .form-check-inline,
.terms-conditions .form-check-inline{
  display: flex !important;
}

.form-check-input[type=checkbox] {
  margin-right: 0.5rem !important;
}

.ai-tooltip-text{
  text-decoration: underline;
  text-decoration-color: white;
  font-style: normal;
  cursor: pointer;
}


.bs-tooltip-top{
  background-color: $primary-blue;
  opacity: 1 !important;
  border-radius: 5px;
  .tooltip-arrow{
    display: none;
  }
  .tooltip-inner{
    background-color: $primary-blue;
    .ai-tooltip{
      text-align: left;
      font-size: 12px;
      padding: .5rem;
      background-color: $primary-blue;
      font-weight: 400;
    }
  }
}

.package-form-name{
  font-size: 20px;
  display: block;
  font-weight: 600;
  text-transform: capitalize;

}

.package-form-price{
  font-size: 10px;
  color: $primary-blue;
  font-weight: 400;
  .ai-form-price{
    display: block;
    font-size: 14px;
    color: $primary-blue;
    font-weight: 600;
  }
}
.disabled-form-package{
  pointer-events: none;
  opacity: 0.4;
  order: -1;
}

.ai-form-flex-row{
  display: flex;
  @include max-xs{
    flex-wrap: wrap;
  }
}

.ai-step-2-amount {
  display: block;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 2rem;
  font-size: 24px;
  font-weight: 600;
  color: $cyan;
}

.ai-ss-desktop{
  @include max-md{
    display: none;
  }
}

.ai-ss-mobile{
  display: none;
  @include max-md{
    display: block;
  }
  .slick-arrow{
    height: 80px;
    width: 60px;
    background: $cyan;
    top: 6rem;
    z-index: 2;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    &:before{
      content: unset;
    }
    &.slick-prev{
      left: 0;
      border-radius: 0px 50px 50px 0px;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M10.828 12l4.95 4.95-1.414 1.414L8 12l6.364-6.364 1.414 1.414z' fill='rgba(12,35,61,1)'/%3E%3C/svg%3E");
      top: 21vh;

      @include max-xs-landscape {
        top: 44vh;
      }
    }
    &.slick-next{
      right: 0;
      border-radius: 50px 0px 0px 50px;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z' fill='rgba(12,35,61,1)'/%3E%3C/svg%3E");
      top: 21vh;

      @include max-xs-landscape {
        top: 44vh;
      }
    }
  }
  .slick-dots{
    li{
      button{
        z-index: 2;
        &:before{
          color: rgba($cyan, 0.25) !important;
        }
      }
      &.slick-active{
        button{
          &:before{
            color: $cyan !important;
          }
        }
      }
    }
  }
  .data-wrapper{
    max-width: 95% !important;
  }
  .accordion-header{
    padding: 0 !important;
    button{
      font-size: 12px !important;
    }
  }
  .accordion-body{
    padding: 1rem 0 !important;
  }
  .what-it-means{
    grid-gap: 12px !important;
  }
}

//high-bill-notice
.slider-container{
  position: relative;
  .high-bill-notice{
    position: absolute;
    background: $primary-blue;
    width: 250px;
    text-align: center;
    padding: 1rem;
    right: 16rem;
    bottom: 4rem;
    border-radius: 5px;
    &:after{
      content: "";
      width: 40px;
      height: 40px;
      background: $primary-blue;
      position: absolute;
      bottom: -20px;
      left: 0;
      right: 0;
      margin: auto;
      transform: rotate(45deg);
    }
    p{
      margin-bottom: 0 !important;
      font-size: 10px;
      color: $green;
      position: relative;
      z-index: 2;
    }
    @include max-lg{
      right: 3rem;
      p{
        padding: 0 !important;
      }
    }
    @include max-ipad-mini{
      right: 1rem;
    }
    @include max-xs{
      right: 0;
      bottom: 3rem;
      &:after{
        content: unset;
      }
    }
  }
}

.what-it-means{
  li{
    position: relative;
    &.disabled{
      &:hover{
        span.tooltip-data{
          display: none;
        }
      }
    }

    span.tooltip-data{
      background: $primary-blue;
      position: absolute;
      font-size: 10px;
      color: white;
      z-index: 1;
      padding: 5px;
      border-radius: 5px;
      white-space: nowrap;
      top: calc(50% - 30px);
      bottom: auto;
      margin: 0;
      display: none;
    }
    &:hover{
      span.tooltip-data{
        display: block;
        //display: none;
      }
    }
  }
}

.running-hours{
  //display: none;
  text-align: center;
  h5{
    font-size: 18px;
    font-weight: 500;
    padding: .5rem;
    font-weight: 600;
  }
  .running-hours-count{
    width: auto;
    display: inline-block;
    background-color: $cyan;
    border-radius: 5px;
    padding: 6px 13px;
    font-weight: 600;
  }
}

// Book Consultation page
.ai-start-saving-container{
  &.book-consultation {
    display: block;

    .step-3 {
        margin-top: 2rem;
    }

    .ai-page-header{
      padding-bottom: 0 !important;
      h2{
        max-width: 1100px;
      }
    }

  }
}


//New Field additions
.ai-form-group{
  input[type="checkbox"]{
    cursor: pointer;
  }
  //Number Field
  &.number{
    input[type="number"]{
      -moz-appearance:textfield;
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button{
        appearance: none;
        -webkit-appearance: none;
      }
      //&.value-exceeded{
      //  //color: red;
      //}
    }
  }
}

.commercial-thank-you-container{
  margin-top: calc(120px + 6rem);
  .user-preferences{
    .pick-solution-rental-period{
      .pick-solution{
        button{
          padding: .6rem 25px;
        }
      }
    }
    .pick-solution{
      button{
        padding: .6rem 2rem;
      }
      @include max-sm{
        button{
          margin: 0 0.4rem 0 0 !important;
        }
        .ai-button-row{
          align-items: flex-start;
          button{
            margin: 0 .4rem 0 0 !important;
          }
        }
      }
    }
    @include max-sm{
      .pick-solution-rental-period{
        padding-bottom: 0;
        button:last-of-type{
          margin-left: 0;
        }
      }
    }
  }
  @include max-sm{

  }
  .commercial-content-container{
    padding-top: 2rem;
    @include max-sm{
      padding-top: 2rem;
      &>div{
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
}

//Commercial buttons
.ai-step-form{
  .commercial{
    .solution-preference{
      label{
        width: 150px !important;
      }
      @include md-to-lg{
        h6{
          margin-bottom: 1rem;
        }
        label{
          width: 125px !important;
          margin-top: 0
        }
      }
    }
    @include max-sm{
      .solution-preference{
        h6{
          margin-bottom: 1rem;
        }
      }
      .form-check-inline{
        margin-right: .5rem;
        label.form-check-label:not([for="terms"], [for="get_a_quote"]){
          margin-top: 0;
          width: 125px !important;
        }
      }
    }
  }
}


//Start Saving Commercial Thank you
.ai-commercial-start-saving-reviews{
  .container{
    padding: 0;
    .slick-slide{
      @include max-sm{
        border: 1px solid transparent;
      }
    }
    .ai-testimonial-card{
      padding: 1rem;
      width: 100%;
      @include md-to-lg{
        padding-left: 0;
        padding-right: 0;
      }
      @include max-sm{
        padding-left: 0;
        padding-right: 0;
      }
      .ai-testimonial-content{
        font-size: 16px;
        font-weight: 400;
        line-height: normal;
        padding: 1rem;
        padding-bottom: 1.5rem;
        margin-bottom: 1.5rem;
        .ai-testimonial-name.f-20{
          font-size: 16px !important;
          padding: 0 1rem;
          background: $cyan;
          height: 2rem;
          color: $blue;
          font-weight: 600;
          bottom: -1rem;
        }
        p{
          font-size: 16px;
          font-weight: 400;
          line-height: normal;
          margin-bottom: .5rem !important;
        }
      }
      .ai-testimonial-details{
        span{
          color: $cyan;
        }
      }
    }
    .slick-dots{
      padding-left: 1rem;
      text-align: left;
      bottom: -2rem;
    }
  }
}

.start-saving-commercial-footer{
  position: relative;
  z-index: 1;
  .row{
    padding-top: 4rem;
    & > div{
      border-top: 1px solid $cyan;
      z-index: 1;
      padding-top: 3rem;
    }
  }
  p{
    position: relative;
    z-index: 1;
  }
  &:before{
    content: "";
    height: 100%;
    top: 0;
    width: 100vw;
    display: block;
    position: absolute;
    left: calc(calc(100vw - 1275px) / 2 * -1);
    background: $blue;
    z-index: 0;
  }
  @include max-sm{
    .container{
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
    &:before{
      left: -32px;
    }
  }
  @include md-to-lg{
    &:before{
      left: -32px;
    }
  }
}



//Responsive
.ai-form-group{
  @include max-sm{
    &.monthly_kwh, &.monthly_diesel{
      width: 100%;
      margin: 0
    }
    &.is_for_business{
      h6{
        width: 100%;
      }
    }
    &.terms{
      width: 100%;
      margin-top: 2rem !important;
      &:last-of-type{
        margin-top: 0 !important;
      }
    }
  }
}

//Responsive Popup
.ai-start-saving-container{
  .show-popup{
    .popup-content-container{
      & > button{
        @include max-sm{
          right: 1.5rem;
          top: 1rem;
        }
      }
    }
  }
}


//Residential Start Saving Buttons
.ai-residential-slider-buttons{
  justify-content: space-between;
  display: flex;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  .ai-residential-slider-link-wrapper{
    width: 32.5%;
    text-align: left;
    .ai-residential-slider-link, a{
      cursor: pointer;
      display: flex;
      flex-direction: column;
      text-decoration: none;
      color: $blue;
      border: 2px solid $blue;
      border-radius: 15px;
      padding: 1rem;
      position: relative;
      .slider-link-title{
        font-size: 20px;
        font-weight: 600;
        line-height: 24px;
      }
      .slider-link-subtitle{
        font-size: 18px;
      }
      i{
        position: absolute;
        height: fit-content;
        top: 0;
        bottom: 0;
        right: 1rem;
        left: auto;
        margin: auto;
        font-size: 28px;
        font-weight: 600;
        transition: transform 0.5s ease;
      }
      &:hover{
        i{
          transform: translateX(0.5rem);
        }
      }
    }
  }
  &.start-saving{
    .ai-residential-slider-link-wrapper{
      .ai-residential-slider-link, a{
        border-color: $cyan;
        background: radial-gradient(circle, $cyan 50%, transparent 50%);
        background-repeat: no-repeat;
        background-size: 600px 600px;
        background-position-x: 200%;
        background-position-y: 120px;
        transition: background 0.7s ease, color 0.5s ease;
        &:hover{
          background-position-y: center;
          background-position-x: center;
        }
      }
    }
    @include max-md{
      flex-direction: column;
      .ai-residential-slider-link-wrapper{
        width: 100%;
      }
    }
  }
  &.home-slider{
    .ai-residential-slider-link-wrapper{
      .ai-residential-slider-link, a{
        background: radial-gradient(circle, $blue 50%, transparent 50%);
        background-repeat: no-repeat;
        background-size: 600px 600px;
        background-position-x: 200%;
        background-position-y: 120px;
        transition: background 0.8s ease, color 0.5s ease;
        &:hover{
          background-position-y: center;
          background-position-x: center;
          color: $white;
        }
      }
    }
  }
  @include max-md {
    .ai-residential-slider-link-wrapper{
      padding-bottom: 1rem;
      &:last-child{
        padding-bottom: 0;
      }
    }
    &.residential{

    }
  }

}


.solar-calculator-slider.container{
  .slider-buttons{
    width: 100%;
  }
  .ai-price-slider{
    width: 100% !important;
  }
}