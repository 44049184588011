/**
POPUP FORM
 */

.ai-popup-form-wrapper{
  background: #FFFFFF;
  max-width: 80%;
  margin: 0 auto;
  padding-left: 108px;
  padding-right: 108px;
  border-radius: 15px;

  @include max-md{
    padding: 50px;
  }
  @include max-xs{
    padding: 1rem;
    max-width: 100%;
    max-height: 100vh;
    overflow: scroll;
  }
  form{
    text-align: center;
    hr{
      width: 100%;
      margin: 1rem 0;
    }
    .customer_details{
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      .ai-form-group{
        width: 30%;
        display: inline-flex;
        flex-direction: column;
        margin-bottom: 1.6rem;
        text-align: left;
        label{
          color: $primary-blue;
        }
        &.radio{
          width: 100%;
          flex-direction: row;
          flex-wrap: wrap;
          margin-top: 0.5rem;
          margin-bottom: 10px;
          h6{
            color: $primary-blue;
            margin-right: 1rem;
            margin-bottom: 0;
            line-height: normal;
          }
          input{
            margin-right: 0.4rem;
          }
          label{
            padding-top: 2px;
          }
          .invalid-feedback{
            width: 100%;
            text-align: left;
          }
        }
        @include max-md{
          width: 48%;
        }
        @include max-sm{
          width: 100%;
          margin-bottom: 1rem;
        }
      }
    }
    .terms{
      text-align: left;
      margin-top: 1rem;
      margin-bottom: 48px;
      label{
        color: $primary-blue;
        padding-top: 2px;
        a{
          &:hover{
            color: $cyan !important;
          }
        }
      }
      @include max-xs{
        margin-right: 0;
        input{
          margin-right: 0 !important;
        }
      }
    }
  }
  button[type="submit"]{
    border:2px solid #00E881;
    background-size: 230% 230%;
    position: static;
    color: $primary-blue;
  }
}

/**
CUSTOM CHECKBOX
 */
.custom-checkbox{
  flex-direction: row !important;
  flex-wrap: wrap !important;
  width: 50% !important;
  .invalid-feedback{display: block}
  h6{
    width: 100%;
    margin-bottom: 0;
    color: $blue;
  }
  .ai-custom-checkbox{
    padding-left: 0;
    display: inline-block;
    max-width: 50%;
    label{
      width: 150px;
      padding: 10px 0;
      font-size: 1rem;
      font-weight: 600;
      text-align: center;
      border-radius: 5px;
      border: 2px solid $cyan;
      cursor: pointer;
      margin-top: 20px;
      &:hover{
        background: $cyan;
      }
    }
    input{
      height: 0;
      width: 0;
      visibility: hidden;
      position: absolute;
      &:checked ~ label{
        background: $cyan;
      }
    }
  }
  @include max_lg{
    h6{
      min-height: 38.4px;
    }
    .ai-custom-checkbox{
      margin-right: 0.5rem;
      label{
        width: 130px;
      }
      &:last-child{
        margin-right: 0;
      }
    }
  }
  @include max_sm{
    width: 100% !important;
    h6{
      min-height: fit-content;
    }
    .ai-custom-checkbox{
      margin-right: 0.5rem;
      label{
        width: 140px;
      }
      &:last-child{
        margin-right: 0;
      }
    }
  }
}
/**
Dropdown
 */
.ai-form-group.dropdown{
  width: 50% !important;
  h6{
    margin-bottom: 0;
  }
  select{
    margin-top: 20px;
    border-radius: 15px;
    border: 2px solid $blue;
    min-height: 50px;
    text-indent: 18px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='rgba(11,34,62,1)'%3E%3Cpath d='M11.9999 13.1714L16.9497 8.22168L18.3639 9.63589L11.9999 15.9999L5.63599 9.63589L7.0502 8.22168L11.9999 13.1714Z'%3E%3C/path%3E%3C/svg%3E");
    background-size: 28px;
    color: $blue;
  }
  @include max_sm{
    width: 100% !important;
    .ai-custom-checkbox{
      margin-right: 0.5rem;
      label{
        width: 140px;
      }
      &:last-child{
        margin-right: 0;
      }
    }
  }
}