// ===== Media Queries ===== //
@mixin iphone4{
  @media only screen and (max-width: 375px) and (max-height: 480px) {
    @content;
  }
}
@mixin iphone8{
  @media only screen and (max-width: 375px) {
    @content;
  }
}
@mixin iphone8-landscape{
  @media only screen and (max-width: 375px) and (orientation:landscape) {
    @content;
  }
}
@mixin xxs {
  @media only screen and (max-width: 449px) {
    @content;
  }
}
@mixin xs {
  @media only screen and (min-width: 450px) and (max-width: 599px) {
    @content;
  }
}
@mixin max-xs {
  @media only screen and (max-width: 599px) {
    @content;
  }
}
@mixin max-xs-landscape{
  @media only screen and (max-height: 450px) and (orientation:landscape) {
    @content;
  }
}
@mixin sm {
  @media only screen and (min-width: 600px) and (max-width: 1024px) {
    @content;
  }
}
@mixin max-sm {
  @media only screen and (max-width: 767px) {
    @content;
  }
}
@mixin max-ipad-mini{
  @media only screen and (max-width: 768px) {
    @content;
  }
}
@mixin min-md {
  @media only screen and (min-width: 768px) {
    @content;
  }
}
@mixin md {
  @media only screen and (min-width: 768px) and (max-width: 992px) {
    @content;
  }
}
@mixin max-md {
  @media only screen and (max-width: 991px) {
    @content;
  }
}
@mixin md-to-lg {
  @media only screen and (min-width: 768px) and (max-width: 1199px) {
    @content;
  }
}
@mixin min-lg {
  @media only screen and (min-width: 992px) {
    @content;
  }
}
@mixin lg {
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    @content;
  }
}
@mixin max-lg {
  @media only screen and (max-width: 1199px) {
    @content;
  }
}
@mixin min-xl {
  @media only screen and (min-width: 1200px) {
    @content;
  }
}
@mixin xl {
  @media only screen and (min-width: 1200px) and (max-width: 1300px) {
    @content;
  }
}

@mixin xl-wide {
  @media only screen and (max-width: 1366px) {
    @content;
  }
}
@mixin xl-height{
  @media only screen and (max-height: 600px) {
    @content;
  }
}
@mixin xxl-height{
  @media only screen and (max-height: 710px) {
    @content;
  }
}
@mixin xxl {
  @media only screen and (min-width: 1440px) {
    @content;
  }
}
@mixin widescreen {
  @media only screen and (min-width: 1600px) {
    @content;
  }
}
@mixin fhd {
  @media only screen and (min-width: 1920px) {
    @content;
  }
}

@mixin ss {
  @media only screen and (max-width: 374px) {
    @content;
  }
}
@mixin xs-landscape {
  @media only screen and (min-width: 320px) and (max-height: 560px) {
    @content;
  }
}
// Internet Explorer
@mixin ie {
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    @content;
  }
}

/** SAFARI **/
@media not all and (min-resolution:.001dpcm) {
  //  Banner Nav Items
  .banner-nav{
    ul.banner-nav-items{
      li{
        margin-bottom: 5px;
        &:last-child{
          margin-bottom: 0px;
        }
      }
    }
  }
  #must-know {
    .owl-theme .owl-dots{
      .owl-dot{
        margin-right: 5px;
        &:last-child{
          margin-right: 0px;
        }
      }
    }
  }

  .homepage-banner{
    .container-fluid{
      height: 100%;
      justify-content: flex-end;
      display: flex;
      flex-direction: column;
    }
  }
}
