.ai-maintenance-repair-cta-card{
  border-radius: 15px;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center center !important;
  padding: 3.8rem 4.5rem;
  position: relative;
  z-index: 2;
  .ai-maintenance-repair-cta-image-container{
    width: 100%;
    height: 100%;
    position: relative;
    img{
      height: auto;
      width: 370px;
      position: absolute;
      bottom: -30px;
      left: 1rem;
      right: 0;
      top: auto;
      margin: auto;
    }
  }
  .ai-maintenance-repair-cta-card-details{
    text-align: center;
    .ai-cta-icon{
      height: 70px;
      width: 70px;
      margin: 0 auto 2rem auto;
    }
    h3{
      font-weight: 600;
      margin-bottom: 1.5rem;
    }
    .ai-maintenance-repair-cta-card-details-contact{
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 3rem;
      margin-top: 2.5rem;
      a{
        color: $blue;
        font-weight: 600;
        text-decoration: none;
        font-size: 22px;
        display: inline-flex;
        align-items: center;
        svg{
          margin-right: 0.4rem;
          height: 26px;
          width: 26px;
        }
        &:hover{
          color: $white;
        }
      }
    }
  }

  //LIST
  .ai-cta-list{
    ul{
      list-style: none;
      padding: 0;
      margin: auto;
      text-align: center;
      @media screen and (min-width: 1200px) {
        padding-right: 1.5rem;
      }

      li{
        list-style: none;
        padding: 0;
        display: inline-block;
        //flex-direction: row;
        //justify-content: center;
        text-align: center;
        margin-bottom: 2rem;
        font-size: 20px;
        &:last-child{
          margin-bottom: 0;
        }
        &:before{
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='rgba(11,34,62,1)'%3E%3Cpath d='M9.9997 15.1709L19.1921 5.97852L20.6063 7.39273L9.9997 17.9993L3.63574 11.6354L5.04996 10.2212L9.9997 15.1709Z'%3E%3C/path%3E%3C/svg%3E");
          margin-top: 0.1rem;
          content: "";
          //display: block;
          height: 28px;
          min-width: 28px;
          margin-right: 0.5rem;
          background-repeat: no-repeat;
          vertical-align: top;
          display: inline-block;
        }
      }
    }
  }



  //IPAD PRO
  @include max-lg{
    padding: 3rem 2rem ;
    background-position: left center !important;
    & > .row{
      align-items: center;
      .ai-maintenance-repair-cta-image-container{
        margin-bottom: 2rem;
        img{
          position: static;
          bottom: 0;
        }
      }
      .ai-maintenance-repair-cta-card-details{
        text-align: center;
        ul{
          li{
            margin-bottom: 1rem;

          }
        }
        .ai-maintenance-repair-cta-card-details-contact{
          flex-direction: column;
          gap: 1rem;
          align-items: center;
        }
      }

    }
  }

  //IPAD AIR
  @include max-md{
    background-position: right center !important;
    & > .row{
      flex-direction: column-reverse;
      align-items: center;
      .ai-maintenance-repair-cta-image-container{
        height: 400px;
        margin-bottom: 2rem;
        img{
          position: absolute;
          bottom: 0;
        }
      }
      .ai-maintenance-repair-cta-card-details{
        ul{
          max-width: 80%;
          li{
          }
        }
        .ai-maintenance-repair-cta-card-details-contact{
        }
      }

    }
  }

  //Mobile
  @include max_sm{
    padding: 2rem;
    & > .row{
      .ai-maintenance-repair-cta-image-container{
        height: 250px;
        margin-bottom: 2rem;
        img{
          position: absolute;
          bottom: 0;
          width: 250px;
          left: 0;
          right: 0;
        }
      }
      .ai-maintenance-repair-cta-card-details{
        padding-left: 0;
        padding-right: 0;
        ul{
          max-width: 100%;
          li{
            justify-content: flex-start;
            text-align: left;
          }
        }
        .ai-maintenance-repair-cta-card-details-contact{
          align-items: flex-start;
        }
      }

    }
  }


}