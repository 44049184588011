.ai-option-card{
  &.own{
    .ai-card-header{
      background: linear-gradient(90deg, #00E881 0%, #0CE4A7 100%) 0% 0% no-repeat padding-box;
    }
  }
  &.rent{
    .ai-card-header{
      background: linear-gradient(90deg,  #0DE4AB 0%, #19E0D1 100%) 0% 0% no-repeat padding-box;
    }
  }
  &.subscription{
    .ai-card-header{
      background: linear-gradient(90deg,  #1AE0D5 0%, #26DCFA 100%) 0% 0% no-repeat padding-box;
    }
  }
  .ai-options-card-wrapper{
    display: flex;
    flex-direction: column;
    height: 100%;
    max-width: 90%;
    text-align: left;

    border-radius: 15px;
    overflow: hidden;
    margin: 0 auto;
    .ai-card-header{
      padding: 0.5rem;
      text-align: center;
      position: relative;
      span.ai-options-card-title{
        font-size: 32px;
        font-weight: 600;
        text-transform: uppercase;
        display: block;
        width: 100%;
      }
      span.ai-options-card-subtitle{
        font-size: 20px;
        display: block;
        width: 100%;
      }

      .popular-banner{
        position: absolute;
        rotate: 30deg;
        top: 0.4rem;
        right: -2rem;
        background: white;
        padding: 0.5rem 3rem;
        font-size: 12px;
        font-weight: 600;
      }
    }
    .ai-card-body{
      border: 2px solid $cyan;
      padding-bottom: 2rem;
      border-bottom-left-radius: 15px;
      border-bottom-right-radius: 15px;
      height: 100%;
      display: flex;
      flex-direction: column;
      text-align: center;
      .ai-card-body-subtitle{
        padding: 1.5rem 2.2rem 1.8rem 2.2rem;
        text-align: center;
        span{
          font-size: 14px;
          color: $blue;
        }
      }
      .ai-card-body-list{
        text-align: left;
        padding-bottom: 1.5rem;
        ul{
          list-style: none;
          padding-left: 1.5rem;
          padding-right: 1.5rem;
          li{
            margin-bottom: 1rem;
            color: $blue;
            font-size: 18px;
            display: inline-flex;
            &:before{
              content: "";
              display: inline-block;
              height: 24px;
              width: 24px;
              margin-right: .5rem;
              background-repeat: no-repeat;
              background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M10.0007 15.1709L19.1931 5.97852L20.6073 7.39273L10.0007 17.9993L3.63672 11.6354L5.05093 10.2212L10.0007 15.1709Z' fill='rgba(0,220,253,1)'%3E%3C/path%3E%3C/svg%3E");
              vertical-align: top;
              color: red;
            }
            &:last-child{
              margin-bottom: 0;
            }
            &:empty{
              display: none;
            }
          }
        }
      }
      a.ai-button{
        margin: auto auto 0 auto;
      }
    }
  }
  @include max-sm{
    .ai-options-card-wrapper{
      max-width: 100%;
    }
    margin-bottom: 2rem;
  }
}