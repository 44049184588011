.ai-maintenance-repair-card{
  border: 3px solid $cyan;
  border-radius: 15px;
  //background-color: $cyan;
  @media screen and (min-width: 1200px) {
    padding: 2.9rem 2.5rem;
  }

  .ai-maintenance-repair-card__cta{
    text-align: center;

    .ai-maintenance-repair-card__cta-card{
      height: fit-content;
      display: flex;
      flex-direction: column;
      padding: 3rem 2rem;
      background: linear-gradient(313deg, $cyan 0%, $green-3 );
      border-radius: 15px;
      .ai-cta-card-title{
        font-size: 32px;
        font-weight: 600;
        color: $blue;
        text-align: center;
        margin-bottom: 1.5rem
      }
      .ai-cta-card-price{
        font-size: 28px;
        line-height: 28px;
        font-weight: 600;
        display: block;
        width: fit-content;
        background: $white;
        padding: 1rem 2rem;
        border-radius: 100px;
        margin: 0 auto 1.5rem auto;
      }
      .ai-cta-card-list{
        ul{
          @media screen and (min-width: 1200px) {
            max-width: 310px;
          }
          margin: auto;
          li{
            color: $blue;
            font-size: 20px;
            line-height: 1.5;
            &:before{
              background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='rgba(11,34,62,1)'%3E%3Cpath d='M9.9997 15.1709L19.1921 5.97852L20.6063 7.39273L9.9997 17.9993L3.63574 11.6354L5.04996 10.2212L9.9997 15.1709Z'%3E%3C/path%3E%3C/svg%3E");
            }
          }
        }
      }
    }
    button{
      width: fit-content;
      margin: 2.5rem auto auto auto;

      &:hover {
        color: $white;
      }
    }

    @media screen and (min-width: 1200px) {
      padding-right: 4rem;
    }



  }
  .ai-maintenance-repair-card__details{
    .ai-solution-title{
      font-size: 20px;
      line-height: 1.5;
      max-width: 93%;
    }
    .ai-solution-details{
      margin-top: 3rem;
      .ai-solution-details__title{
        margin-bottom: 1rem;
        font-size: 20px;
        font-weight: 600;
      }
      .ai-solution-details__subtitle{
        font-size: 14px !important;
        font-weight: 500;
      }
      .ai-cta-card-list{
        margin-top: 1.5rem;
        ul{
          @media screen and (min-width: 1200px) {
            max-width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
          }
          margin: auto;
          li{
            width: 46%;
            display: inline-flex;
            color: $white;
            font-size: 14px;
            line-height: 1.8;
            &:before{
              background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='rgba(0,220,253,1)'%3E%3Cpath d='M9.9997 15.1709L19.1921 5.97852L20.6063 7.39273L9.9997 17.9993L3.63574 11.6354L5.04996 10.2212L9.9997 15.1709Z'%3E%3C/path%3E%3C/svg%3E");
              margin-top: -2px;
            }
          }
        }
      }
    }
    .ai-solution-cost{
      //margin-top: 1rem;
      padding-top: 2rem;
      border-top: 2px solid $cyan;
      .ai-solution-cost-title{
        margin-bottom: 1rem;
        font-size: 20px;
        font-weight: 600;
      }
      .ai-solution-cost-details{
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 1.4rem;
        margin-bottom: 1.8rem;

        //&:last-child{
        //  margin-bottom: 0;
        //}
        .ai-solution-cost-price-text{
          margin: 0 !important;
          font-size: 16px;
          line-height: 1.7;
          font-style: italic;
          @media screen and (min-width: 1200px) {
            max-width: 72%;
          }
        }
        .ai-cta-card-price{
          font-size: 22px;
          line-height: 28px;
          font-weight: 600;
          display: block;
          width: fit-content;
          background: $white;
          padding: 0.8rem 2rem;
          border-radius: 100px;
          min-width: 125px;
        }
      }
      .ai-button {
        &:hover {
          color: $white;
        }
      }
    }
  }
  .ai-cta-card-list{
    ul{
      list-style: none;
      padding: 0;
      margin: auto;
      li{
        list-style: none;
        padding: 0;
        display: flex;
        flex-direction: row;
        margin-bottom: 2rem;
        text-align: left;
        //&:last-child{
        //  margin-bottom: 0;
        //}
        &:before{
          margin-top: 0.1rem;
          content: "";
          display: block;
          height: 28px;
          min-width: 28px;
          margin-right: 0.5rem;
          background-repeat: no-repeat;
          vertical-align: top;
        }
      }
    }
  }


  //IPAD PRO
  @include max-lg{
    padding: 2rem;
    & > .row{
      flex-direction: row;
      .ai-solution-details{
        ul{
          padding-right: 2rem;
          li{
            width: 100% !important;
          }
        }
      }
      .ai-solution-cost{
        .ai-solution-cost-details{
          margin-top: 1.5rem;
          flex-direction: column;
          align-items: flex-start;
        }
      }
    }
  }

  //IPAD AIR
  @include max-md{
    .ai-maintenance-repair-card__cta{
      margin-bottom: 2rem;
    }
    .ai-maintenance-repair-card__details{
      h4.ai-solution-title{
        max-width: 100%;
      }
      h5.ai-solution-details__subtitle{
        max-width: 90%;
      }
    }
  }


  //MOBILE
  @include max-sm{
    padding: 0 0 2rem 0;
    overflow: hidden;
    .ai-maintenance-repair-card__cta{
      .ai-maintenance-repair-card__cta-card{
        border-radius: 10px 10px 0 0;
        padding: 3rem 1rem
      }
    }
    .ai-maintenance-repair-card__details{
      padding-left: 1rem;
      padding-right: 1rem;
      .ai-solution-details{
        margin-top: 2rem;
      }
    }
    .ai-solution-cost-details{
      button.ai-button{
        width: fit-content;
        margin: auto;
      }
    }
    .ai-solution-cost{
      text-align: center;
      .ai-solution-cost-details{
        justify-content: center;
        align-items: center;
        span.ai-cta-card-price{
          margin: auto;
        }
      }

    }

  }
}