.ai-icon-text{
  &:first-child{
    margin-top: 0rem;
  }
  margin-top: 3rem;
  i{
    height: 80px;
    width: 80px;
    border-radius: 100%;
    background-color: $green;
    font-size: 38px;
    color: $white;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  h4{
    margin-top: 1rem;
    font-weight: 600;
  }
}