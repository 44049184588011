.ai-affiliation-slider{
  padding-bottom: 2rem;
  .ai-slider-image-container{
    img{
      margin: auto
    }
  }
  .slick-dots{
    li{
      &.slick-active{
        button{
          &:before{
            opacity: 1;
            color: $cyan !important;
          }
        }
      }
      button{
        &:before{
          font-size: 12px;
          opacity: 1;
          color: rgba($cyan, 0.2) !important;
        }
      }
    }
  }
}