.ai-our-coverage-info-blocks{
  display: flex;
  flex-direction: column;
  padding-left: 3rem !important;
  @include max-md{
    padding-left: 0 !important;
    width: 100% !important;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 2rem;
    .ai-icon-text{
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      h4{
        font-size: 14px !important;
      }
    }
  }

  span.ai-info-card-icon img {
      width: 115px;
  }

  @include min-lg{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-bottom: 2rem;
        padding-left: 0!important;
        align-items: baseline;
        /* width: 100%!important; */
        .ai-icon-text {
          display: inline-block;
          width: 48%;
          float: left;
          padding-right: 2rem;
      }

      span.ai-info-card-icon img {
        max-width: 110px;
    }
  }

}
.ai-our-coverage-map{
  text-align: center;
  @include max-md{
    width: 100% !important;
  }
}

.ai-icon-text{
  i{
    font-size: 45px;
    width: 90px;
    height: 90px;
    @include max-xs{
      width: 75px;
      height: 75px;
      font-size: 40px;
    }
  }
}