.ai-thank-you-header{
  min-height: 100vh;
  background-size: contain;
  display: flex;
  align-items: center;
  background-attachment: fixed;
  background-size: cover;
  .container{
    .row{
      width: 100%;
    }
  }
  .ai-button-row{
    align-items: flex-start;
  }
}
