.what-we-do-intro{
    font-size: 40px !important;
  @include max-xs{
    font-size: 30px !important;
  }
  a{
    color: $green;

    &:first-child{
      white-space: nowrap;
    }
    &:hover{
      color: $cyan;
    }
  }

  @include max-xs{
    a {
      &:first-child {
        white-space: normal !important;
        display: block !important;
      }
    }
  }
}
.ai-get-switched-title{
  font-size: 24px !important;
  padding-bottom: .1rem;
  font-weight: 400 !important;
    .ai-word-container{
      span{
        margin-bottom: 1rem !important;
      }
    }
  @include max-xs{
    line-height: .8em !important;
    .ai-word-container{
      line-height: .8em;
    }
  }
}
.ai-get-switched-subtitle{
  font-size: 40px ;
  @include max-xs{
    line-height: 50px;
  }
}

.ai-link-card-container {
  a{
    height: 100%;
  }
  @include max-md {
    width: 50% !important;
    margin-left: 0 !important;
  }
  @include max-xs{
    width: 100% !important;
    margin-bottom: 1rem;
    &:last-child{
      margin-bottom: 0;
    }
  }
}

.ai-what-we-do-video{
  .ai-video-section{
    border-radius: 15px;
    overflow: hidden;
    height: 80vh;
    @include max-lg{
      height: fit-content;
    }
    @include widescreen{
      height: 70vh;
    }
    video{
      object-position: 0;
    }
  }
}

.ai-gradient-line{
  height: 4px;
  width: 100%;
  background: linear-gradient(90deg, #00E881 0%, #26DCFB 100%) 0% 0% no-repeat padding-box;
  position: absolute;
  bottom: -1rem;
}

.ai-subtitle-width-short {
  width: 70%;
  margin: 1rem auto;

  @include max-xs {
    width: 100%;
  }
}

.ai-table-component {

  .ai-section-subtitle-small {
    width: 70%;
    margin: auto;

    @include max-lg {
      width: 90%;
    }
  }
  .ai-table-wrapper {

    @include max-lg {
      overflow-x: scroll;
    }

    table {
      table-layout: fixed;
      height: 100%;
      
  
      thead {
        tr {
          th {
            padding-bottom: 1rem;
            &:last-child{
              padding-left: 8px;
              padding-right: 8px;
            }

            p {
              @include max-md {
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
              }
            }
  
            &:nth-child(2) {
              border-right-style: hidden;
              padding-left: 0.5rem;
              p {
                -webkit-column-width: auto; /* Chrome, Safari, Opera */
                -moz-column-width: auto; /* Firefox */
                column-width: auto;
                background: linear-gradient(90deg, #00E881, #26DCFB) left/480px 100%;
                border-radius: 15px 0px 0px 15px;
              }
            }
            &:nth-child(3) {
              border-left-style: hidden;
              padding-right: 0.5rem;
  
              p {
                -webkit-column-width: auto; /* Chrome, Safari, Opera */
                -moz-column-width: auto; /* Firefox */
                column-width: auto;
                background: linear-gradient(90deg, #00E881, #26DCFB) right/850px 100%;
                border-radius: 0px 15px 15px 0px;

                @include max-md {
                  background: linear-gradient(90deg, #00E881, #26DCFB) right/254px 100%;
                }
              }
            }
            p {
              margin: 0rem !important;
              padding: 1rem 1.5rem;
              border-radius: 15px;
            }
  
            &:last-child {
              p {
                background-color: #0B223E;
                color: #FFFFFF;
                border-radius: 15px;
              }
            }
          }
        }
      }
      tbody {
        tr {
  
          &:last-child {
            td {
              div {
                border-radius: 0px 0px 15px 15px;
              }
            }
          }
  
          &:first-child {
            td {
              div {
                border-radius: 15px 15px 0px 0px;
  
                i {
                  margin-bottom: 1rem;
                }
              }
            }
          }
  
          td {
            font-family: 'Poppins', sans-serif;
            padding: 0.1rem 0.5rem 0rem 0.5rem;
            height: 100%;
            width: 25%;
            @include max-sm{
              min-width:235px;
            }

  
  
            div {
              height: 100%;
              margin: 0 !important;
              min-height: 100%;
              max-height: 100%;
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
              font-size: 0.875rem;
              line-height: normal;
              p {
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin-bottom: 0px !important;
                font-size: 0.875rem;
                line-height: normal;
                font-weight: 400;

                @include max-md {
                  height: 100%;
                }
  
                i {
                  display: inline-block;
                  font-size: 2rem;
                }
              }
            }
  
            &:first-child {
              text-align: left;
              
              div {
                background-color: #EDEDED;
                color: #0B223E;
                font-weight: 600;
                padding: 1.5rem 1rem;
                text-align: left;
                justify-content: flex-start;
              }
            }
  
            &:nth-child(2) {
              div {
                background-color: #00E881;
                padding: 1.5rem 1rem;
              }
            }
  
            &:nth-child(3) {
              div {
                background-color: #00DCFD;
                padding: 1.5rem 1rem;
              }
            }
            
            &:last-child {
              div {
                background-color: #0B223E;
                color: #FFFFFF !important;
                padding: 1.5rem 1rem;
  
                i {
                  color: #FFFFFF;
                }
              }
            }
  
          }
        }
      }
    }
  }
}

.ai-referrals {
  img {
    width: 85%;
    @include max-sm{
      width: 100%;
    }
  }
  p {
    font-size: 18px;
  }
  .ai-section-subtitle-small {
    margin-bottom: 0px !important;

    p {
      margin-bottom: 0.5rem !important;
      margin-top: 1.5rem;
    }
  }
}

.ai-budget-section {
  background-color: #F2F3F5;
  padding: 1rem 0 6rem 0;

  .ai-tab-content {
    height: 100%;
    background-color: #FFFFFF;
    padding: 2rem;
    border-radius: 0 0 15px 15px;

    @include max-md {
      padding: 0;
    }
  }

  .ai-accordion {
    display: none;

    @include min-md {
      display: block;
    }

    .ai-accordion-section-tabs {
      margin-right: 1rem;
      .ai-accordion-section-tab{
        &:last-child{
          span{
            border-bottom-color: $green;
            &:after{
              background-color: $green;
            }
          }
        }
      }
    }
  }

  .mobile.ai-tabs-accordion {
    display: block;

    @include min-md {
      display: none !important;
    }

    .accordion-item {
      outline: none;
      border: none;

      .accordion-header {
        color: #0B223E;

        button {
          h2 {
            color: #0B223E;
          }
        }
      }
    }
  }
}


.ai-tab-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: auto;

  @include max-md {
    flex-direction: column;
  }

  .ai-single-col {
    border: 2px solid #26DCFB;
    height: 100%;
    text-align: left;
    padding: 1.5rem;
    margin-right: 0.5rem;
    border-radius: 15px;
    display: flex;
    flex-direction: column;

    @include max-md {
      margin: 0;
      width: 100%;
      padding: 1rem;
    }


    &:last-child {
      margin-right: 0rem;
      margin-left: 0.5rem;

      @include max-md {
        margin: 2rem 0 0 0;
      }
    }

    a {
      margin-top: auto;
      display: inline-flex;
      width: fit-content;
      margin: auto 0 2rem 0;
    }

    .ai-section-header {
      font-weight: 600;

      span {
        display: inline-flex;
        width: 2rem;
        height: 2rem;
        font-size: 1.5rem;
        border-radius: 50%;
        background-color: #26DCFB;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 1.5rem;
        margin: 0px 0.5rem 0px 0px;
        color: #FFFFFF;
      }
    }

    .ai-tab-content-inner {
      p {
        display: flex;

        span {
          display: inline-flex;
        }

        i {
          display: inline-flex;
          font-size: 22px;
          color: #26DCFB;
          margin-right: 1rem;
        }
      }
    }
  }
}


.ai-tab-content-wrapper {
  text-align: left;
  border: 2px solid #00E881;
  border-radius: 15px;
  padding: 2rem;

  p {
    font-size: 16px;
    line-height: 24px;
  }

  .ai-sub-content {
    //padding-left: 2rem;
    p{
      display: flex;
      align-items: flex-start;
      i{
        font-size: 22px;
        color: $green;
        margin-right: 0.5rem;
      }
    }


    @include max-md {
      padding-left: 0rem;
    }
  }

  h4 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 1rem;
  }

  h5 {
    font-size: 16px;
    font-weight: 600;
  }

  .ai-subtitle {
      display: flex;
      align-items: flex-start;
      margin-bottom: 1.5rem;
      i{
        font-size: 22px;
        color: $green;
        margin-right: 0.5rem;
        line-height: 22px;
      }

      @include max-md {
        padding-left: 0rem;
      }
  }

  .ai-button {
    margin-bottom: 2rem;    
  }

  .ai-inner-content-green {
    background-color: rgba(#00E881, 0.1);
    border-radius: 15px;
    padding: 2rem;

    ul {
      li{
        list-style: none;
        position: relative;
        &:before{
          content: "";
          height: 22px;
          width: 22px;
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='rgba(0,232,129,1)'%3E%3Cpath d='M9.9997 15.1709L19.1921 5.97852L20.6063 7.39273L9.9997 17.9993L3.63574 11.6354L5.04996 10.2212L9.9997 15.1709Z'%3E%3C/path%3E%3C/svg%3E");
          position: absolute;
          left: -2rem;
        }
      }
      @include max-md {
        padding-left: 1rem;
      }
    }

    a {
      color: #0B223E;
      text-decoration: underline;
  
      &:hover {
        color: #00E881;
      }

      @include max-md {
        word-break: break-all;
        word-wrap: break-word;
      }
    }
  }
}
