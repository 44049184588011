.ai-brand-info-blocks{
  padding-bottom: 0;
  max-width: 94%;
  margin: 0 auto !important;
  .col-md-4{
    margin-bottom: 2rem;
  }
  .ai-info-card{
    height: 100%;
    margin-bottom: 2rem;
    width: 95%;
    margin: 0 auto;
    justify-content: flex-start;
  }
  @include max-md{
    margin: 0;
    .col-md-4{
      margin-bottom: 1rem;
      width: 100%;
    }
    .ai-info-with-paragraph{
      width: 100%;
    }
  }
}

.ai-product-card{
  margin-bottom: 2rem;
}

.ai-brand-logo{
  @include max-md{
    width: 100% !important;
  }
  @include max-xs{
    img{
      max-width: 60% !important;
    }
  }
}

.ai-brand-intro{
  @include max-md{
    width: 100% !important;
  }
}

.suggested-products{
  @include max-md{
    .col-md-4 {
      width: 50%;
    }
  }
  @include max-xs{
    .col-md-4{
      width: 100%;
      .ai-post-card{
        width: 100%;
      }
    }
  }
}

// Award Wining Archivements
// Top
.ai-awa-top-wrapper {
  .ai-section-title {
    span {
      &:nth-child(3),
      &:nth-child(5) {
        color: $green;
      }
    }
  }
  .ai-aws-logos-wrapper {
    padding-top: 0;
    padding-bottom: 0;
    width: 85%;
    margin: auto;

    @include max-md{
      width: 100%;
    }

    .ai-single-image-container {
      padding-bottom: 1rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      img {
        width: 70%;

        @include max-md{
          width: 100%;
        }
      }
    }
    

  }
}

// Award Wining Archivements
// Bottom
.ai-awa-bottom-wrapper {
  // display: flex;
  // flex-wrap: wrap;
  flex: 0 0 30%;
  justify-content:space-between;
  gap: 2rem;
  justify-content: center;
  padding-bottom: 10rem;

  @include max-md {
    flex-direction: column;
  }

  .ai-awc-wrapper {
    width: 30%;
    display: flex;
    align-self: stretch;

    @include max-md {
      width: 100%;
    }

    .ai-section-title {
      font-size: 1.4rem !important;
      text-align: left !important;
      line-height: 0;
      .ai-word-container {
        line-height: 1.88;
      }
    }

    p {
      text-align: left;
    }
  }
  .ai-awc-inner-content {
    background-color: $white;
    height: 100%;
    border-radius: 15px;
    padding: 2rem;
  }
}

// Intro
.ai-brand-intro{
  position: unset;

  .ai-video-section {
    position: unset;

    .ai-section-video-popup {
      top: 4rem;
    }

    .ai-section-video-container {
      height: 70%;
      margin: auto;
      border-radius: 15px;
      overflow: hidden;
    }
  }

}
