//Service icons on intro
.ai-maintenance-service-icons{
  margin-bottom: 2rem;
  .ai-maintenance-service-icon-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 1.5rem;
    &:last-child{margin-bottom: 0}
    h2.ai-service-title{
      font-size: 24px;
      font-weight: 600;
      margin-bottom: 0;
    }
    img.ai-service-image{
      margin-right: 1rem;
      height: 60px;
      width: 60px;
    }
  }
}

//POPUP
.ai-calendar-popup-container.maintenance-page{
  @include max_lg{
    .ai-popup-form-wrapper{
      max-width: 100%;
    }
  }
  @include max_sm{
    .popup-content-container{
      padding: 2rem 1rem;
      .ai-popup-form-wrapper{
        padding-top: 2rem;
      }
    }
    .popup-content-container{
      &>button{
        top: 0.5rem;
        right: 1.5rem;
      }
    }
  }
}

/**
  EXPLORE SOLUTION SECTION
 */
.ai-explore-solutions-section{
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
  .divider{
    height: 190px;
    width: 100%;
    background: $grey;
    position: absolute;
    bottom: 0;
  }
}

/**
  SLIDER SECTION
 */
.ai-affiliation-slider-section{
  .affiliation-slider{
    .slick-dots{
      button{
        &:before{
          color: rgba($cyan, 0.5);
        }
      }
      .slick-active{
        button{
          &:before{
            color: $cyan;
          }
        }
      }
    }
  }
}

//IPAD PRO
@include max_lg{
  .ai-maintenance-help-section{
    img{
      height: 300px;
      object-fit: cover;
      object-position: bottom;
      margin-bottom: 2rem !important;
      border-radius: 15px;
    }
  }
}

//IPAD AIR
@include max-md{
  .ai-maintenance-intro-image{
    margin-bottom: 2rem !important;
  }
}

//MOBILE
@include max-sm{
  .ai-maintenance-intro-section{}
  .ai-maintenance-help-section{
    img{
      margin-bottom: 2rem !important;
    }
  }
  .ai-explore-solutions-section{
    & > .container{
      padding-right: 18px !important;
      padding-left: 18px !important;
    }
  }
}

.ai-maintenance-slider-subtitle{
  max-width: 60%;
  margin: 0 auto;
  @include max-md{
    max-width: 80%;
  }
  @include max-sm{
    max-width: 100%;
  }
}

.ai-section-video {
  padding: 6rem 0 0;
}

.ai-maintenance-system {
  img {
    width: 430px;
    margin: auto !important;
  }

  .ai-maintenance-left {
    padding-right: 0px;
  }

  .ai-maintenance-right {
    padding-left: 0px;
    a{
      color: $white;
      text-underline-offset: 5px;
      &:hover{
        color: $green;
      }
    }

    @include max-md {
      padding: 0 1rem;
    }
  }

  .ai-section-heading h2.ai-h2 {
    font-size: 33px !important;
    line-height: 1.4;
    font-weight: 600;
    margin-bottom: 2rem;

    .ai-word-container {
      line-height: 1.5;
    }

    @include max-md {
      font-size: 35px !important;
      line-height: 18px !important;
      text-align: center;
      margin-top: 2rem;
    }
  }

  ul {
    font-family: 'Poppins', sans-serif;
    padding-left: 0.5rem;
    max-width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    
    li {
      color: #FFFFFF;
      width: 95%;
      display: inline-flex;
      padding-bottom: 1rem;

      p {
        font-size: 16px;
        margin-bottom: 0px !important;
      }

      &::before {
        background-image: url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 24 24%27 fill=%27rgba%280,220,253,1%29%27%3E%3Cpath d=%27M9.9997 15.1709L19.1921 5.97852L20.6063 7.39273L9.9997 17.9993L3.63574 11.6354L5.04996 10.2212L9.9997 15.1709Z%27%3E%3C/path%3E%3C/svg%3E");
        width: 28px;
        content: "";
        display: block;
        height: 28px;
        min-width: 28px;
        margin-right: 0.5rem;
        background-repeat: no-repeat;
        vertical-align: top;
      }
    }
  }
}