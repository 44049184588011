//Slider dots
.ai-slide-slider {
  .slick-list{
    margin-bottom: 4rem;
  }
  .slick-dots{
    bottom: -60px;
    li{
      margin: 2px;
      width: 15px;
      button{
        &:before{
          opacity: 1;
          color: rgba($white, 0.5);
          font-size: 14px;
        }
      }
      &.slick-active{
        button{
          &:before{
            color: $white;
          }
        }
      }
    }
  }
}
//Section Heading
.ai-carousel-section{
  .ai-section-heading{
    p{
      //margin-bottom: 0;
    }
  }
  //Different Background Settings
  &.ai-bg-offwhite{
    //Green Curve
    .ai-green-curved-section{
      clip-path: ellipse(85% 100% at 50% 100%);
      background-color: $green;
      padding-top: 4rem;
      padding-bottom: 2rem;
      @include max-xs{
        clip-path: ellipse(200% 100% at 50% 100%);
        .container{
          .row{
            padding-top: 2rem !important;
            .ai-section-title{
              padding-left: 1rem;
              padding-right: 1rem;
            }
          }
        }
      }
      .slick-dots{
        li{
          button{
            &:before{
              color: rgba($white, 0.5);
            }
          }
          &.slick-active{
            button{
              &:before{
                color: $white;
              }
            }
          }
        }
      }
    }
      .slick-dots{
        li{
          button{
            &:before{
              color: rgba($cyan, 0.25);
            }
          }
          &.slick-active{
            button{
              &:before{
                color: $cyan;
              }
            }
          }
        }
      }
    .ai-section-subtitle{
      margin-bottom: 4rem !important;
    }
    }
  }

//affiliation-slider
.affiliation-slider-container{
  width: 98% !important;
  margin: 0 auto;
}
.affiliation-slider{

  margin: 0 auto;
  .slick-slider{
    margin-top: 2rem;
    img{
      width: 70%;
      height: auto;
      margin: 0 auto;
    }
  }
  @include max-sm{
    .container{
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
    .slick-slider{
      img{
        width: 95%;
      }
    }
  }
}


.slick-dots{
  bottom: -60px;
  z-index: 2;
  li{
    margin: 2px;
    width: 15px;
    button{
      &:before{
        opacity: 1;
        color: rgba($white, 0.5);
        font-size: 14px;
      }
    }
    &.slick-active{
      button{
        &:before{
          color: $white;
        }
      }
    }
  }
}
