.ai-link-card{
  display: block;
  width: 100%;
  height: auto;
  border: 2px solid $cyan;
  border-radius: 15px;
  text-decoration: none;
  color: $blue;
  padding: 2rem;
  position: relative;
  overflow: hidden;
  z-index: 2;
  background: radial-gradient(circle, #26DCFB 50%, transparent 50%);
  background-repeat: no-repeat;
  background-size: 250% 250%;
  background-position-x: 160%;
  background-position-y: 200px;
  transition: background-position .6s ease;
&.ai-link-card-95{
    max-width: 95%;
  }
  h3{
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 1rem;
    @include max-lg{
      font-size: 24px;
    }
  }
  p{
    font-size: 16px;
    margin-bottom: 1rem !important;
  }
  i{
    font-size: 32px;
    display: block;
    transition: transform .5s ease;
  }
  &:after{
    //content: "";
    //position: absolute;
    //height: 10px;
    //width: 10px;
    //left: -10px;
    //bottom: -10px;
    //background: $cyan;
    //z-index: -1;
    //border-radius: 100%;
    //transition: transform .7s ease;
  }
  &:hover{
    color: $blue;
    //&:after{
    //  transform: scale(120);
    //}
    background-position-x: center;
    background-position-y: center;
    i{
      transform: translateX(15px);
    }
  }
}

.ai-link-card-row{
  flex: unset;
  justify-content: space-between;
  @include max-lg{
    gap: 1rem;
  }

  &.ai-hyp-card-row{
    gap: 50px;
    @include max-lg{
      gap: 25px;
      h3{
        font-size: 20px !important;
      }
    }
    .ai-link-card{
      padding: 1.5rem 2rem;
      i{
        font-size: 28px;
      }
    }
  }
  div{
    flex: 1;

    @include max-md{
      flex: unset;
      width: 100%;
      border-radius: 10px;
    }
  }
}

.link-card-row{
  @include max-md{
    margin: 0 !important;
    .col-md-5{
      width: 50%;
    }
  }
  @include max-xs{
    margin-left: 0 !important;
    padding: 0;
    .col-md-5{
      width: 100%;
      padding: 0;
      margin-bottom: 1rem;
    }
  }
}

.ai-product-link-cards{
  a{
    height: 100% !important;
  }
}