//Video
.ai-video-container{
  height: 550px;
  & > div{
    height: 100% !important;
  }
  @include max-xs{
    height: 300px;
  }
  iframe{
    height: 100%;
    width: 100%;
    border-radius: 20px 20px 0 0;
  }
}

.ai-video-section{
  height: 80vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  @include max-lg{
    height: auto;
  }
  @include max-md{
    height: 400px;
  }
  @include max-xs{
    height: 219px;
  }
  &:hover{
    &:before{
      height: 80px;
      width: 80px;
      box-shadow: 0px 0px 1px 35px rgb(255 255 255 / 37%);
    }
  }
  &.active-popup{
    &:after{
      background-color: rgba($blue, 0.9)
    }
  }
  &:after{
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 2;
    left: 0;
    top: 0;
  }
  &:before{
    content: '';
    height: 65px;
    width: 65px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zM10.622 8.415a.4.4 0 0 0-.622.332v6.506a.4.4 0 0 0 .622.332l4.879-3.252a.4.4 0 0 0 0-.666l-4.88-3.252z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
    box-shadow: 0px 0px 1px 20px rgb(255 255 255 / 37%);
    position: absolute;
    border-radius: 100%;
    background-size: 130%;
    background-position: center;
    transition: box-shadow .5s ease, height .5s ease, width .5s ease;
    z-index: 2;
  }
  .ai-section-video-container{
    width: 100%;
    height: 100%;
    video{
      width: 100%;
      object-fit: contain;
      object-position: 0px -70px;
      @include max-lg{
        object-position: center center;
      }
    }
    &.youtube{
      & >div{
        height: 100%;
        iframe{
          scale: 1.5;
          @include max-sm{
            scale: 1.3;
          }
        }
      }
    }
  }
  iframe{
    width: 100%;
    height: 101%;
  }
  .ai-section-video-popup{
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    margin: 0 auto;
    z-index: 3;
    height: 79vh;
    width: 70%;
    &.youtube-iframe{

      width: 70%;
    }
    @include max-lg{
      top: auto;
      width: 80%;
      height: 80%;
      margin: auto;
    }
    span{
      cursor: pointer;
      position: absolute;
      right: -3rem;
      @include max-sm{
        right: -2rem;
        top: -1.5rem;
      }
      i{
        color: $white;
        font-size: 36px;
      }
      &:hover{
        i{
          color: $cyan;

        }
      }
    }
    .ai-popup-video-container{
      height: 100%;
    }
  }
}

.video-tabs{
  display: flex;
  gap: 10px;
  justify-content: space-between;
  .ai-tab{
    &:hover{
      .ai-video-tab-icon{
        display: none;
      }
    }
    &[aria-selected="true"]{
      .ai-video-tab-icon{
        display: block;
      }
    }
  }
  li{
    margin: 0 !important;
    flex: 1;
  }
  .ai-video-tab-icon{
    display: none;
    padding-top: 1rem;
    i{
      font-size: 1.3rem;
    }
  }
}