.why-alumo-tabs.ai-tabs{
  .ai-tab{
    padding: 2rem;
    border-bottom: 2px solid $cyan;
  }
  .ai-tab-content{
    padding: 0 7rem !important;
    .tab-wrapper{
      padding-top: 4rem;
      padding-bottom: 4rem;
      .ai-tab-content-title{
          font-size: 32px !important;
      }
      .ai-content-subtitle{
        font-size: 24px !important;
      }
      .ai-our-coverage-map{
        padding-left: 5rem !important;
      }
    }
    .row{

    }
  }
  img{
    width: 100%;
    height: 300px;
    object-fit: cover;
    border-radius: 15px;
  }
  .ai-tab-list{
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 0.5rem;
    flex-wrap: nowrap;
    li{
      width: 100% !important;
      margin-right: 0 !important;
      span.ai-tab-heading{
        font-size: 20px ;
        font-weight: 600;
        padding-bottom: .3rem;
      }
      span.ai-tab-text{
        font-size: 14px !important;
      }
    }
  }
}



.ai-why-alumo-curve{
  background-position-y: -1px;
  background-repeat: no-repeat;
  background-size: contain;
  @include max-lg{
    background-size: auto 330px;
    background-position: top center;
    h2{
      padding-left: 2rem;
      padding-right: 2rem
    }
  }
  @include max-xs{
    background-size: auto 400px;
    background-position: top center;
  }
}
.why-alumo-info-cards{
  .ai-info-card{
    width: 85%;
    justify-content: center;
    text-align: center;
    align-items: center;
    height: 100%;
    h3{
      color: $cyan;
      text-align: center !important;
      font-size: 36px !important;
    }
    h4{
      text-align: center;
      font-weight: 600;
    }
  }
  @include max-md{
    margin: 0 !important;
  }
}