//Mobile Typography
.ai-mobile-title-center{
  @include max-md{
    width: 90% !important;
    margin: 0 auto;
  }
}

//Mobile Margins
.ai-m-m-auto{
  @include max-md{
    margin: 0 auto;
  }
}

//mobile width settings
.ai-m-width-auto{
  @include max-md{
    width: auto !important
  }
}