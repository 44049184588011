.ai-list-items {
    padding-left: 0.99rem;
    margin: 2rem 0 3rem 0 !important;

    li {
        margin-bottom: 0px;
        display: flex;
        list-style: none;

        &:before{
            content: "";
            display: inline-block;
            height: 24px;
            width: 24px;
            margin-right: .5rem;
            background-repeat: no-repeat;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M10.0007 15.1709L19.1931 5.97852L20.6073 7.39273L10.0007 17.9993L3.63672 11.6354L5.05093 10.2212L10.0007 15.1709Z' fill='rgba(0,220,253,1)'%3E%3C/path%3E%3C/svg%3E");
            vertical-align: top;
            color: red;
          }

        p {
            margin-bottom: 0.7rem !important;
            font-size: 1.3rem;
            color: #0B223E;
        }
    }
}