ul.check-list{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0;
  margin-bottom: 2rem;
  li{
    width: 50%;
    list-style: none;
    display: inline-flex;
    font-size: 14px;
    color: $primary-blue;
    margin-bottom: 1rem;
    align-items: center;

    span{
      padding-right: .5rem;
      i{
        color: $cyan;
        font-size: 20px;
      }
    }
  }
  @include max-xs{
    li{
      width: 100%;
      margin-bottom: .5rem;
    }
  }
}

.ai-solution-intro{
  overflow: hidden;
  @include max-md{
    .row{
      flex-direction: column-reverse;
      .col-md-6{
        width: 100%;
        img{
          margin: 0 auto;
          margin-top: 2rem;
          padding-bottom: 2rem;
        }
      }
    }
  }
}

.ai-product-details{
  @include max-xs{
    margin-bottom: 2rem;
  }
}
.ai-product-image{
  @include max-xs{
    img{
      width: 100%;

    }
  }
}

.ai-solutions-curve{
  background-position: 0;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top;
  .row{

  }
  @include max-lg{
    background-size: auto;
  }
}

.ai-solutions-info-cards{
  max-width: 96% !important;
  margin: 0 auto !important;
  .ai-info-card{
    height: 100%;
    justify-content: flex-start;
    p{
      margin-bottom: 0 !important;
    }
  }
  @include max-lg{
   max-width: 100%;
    width: 100%;
    .col-md-4{
      width: 50% !important;

    }
  }
  @include max-xs{
    max-width: 100% !important;
    .col-md-4{
      width: 100% !important;
      padding: 0;
    }
  }
}

.ai-solutions-cta{
  @include max-lg{
    margin-top: 4rem
  }
  @include max-xs{
    margin-top: 4rem;
    img{
      width: 100%;
    }
    .ai-button-row{
      display: flex !important;
      flex-direction: column !important;
      justify-content: flex-start;
      align-items: flex-start;
      .ai-button{
        text-align: center;
        padding: 8px 30px;
      }
    }
  }
}