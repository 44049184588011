.ai-rely-on-sun-power-section{
  h2{
    @media screen and (min-width: 1200px) {
      padding-right: 1rem;
    }
  }
  p.ai-section-subtitle{
   padding-top: 0;
    margin-bottom: 3rem;
  }
}