.ai-post-card{
  width: 95%;
  display: block;
  border-radius: 15px ;
  text-decoration: none;
  color: $blue;
  @include max-lg{
    margin: 0 auto;
  }
  @include max-md{
    margin: 0 auto;
  }
  .ai-post-card-img{
    height: 300px;
    width: 100%;
    background-size: auto 105%;
    background-position: center;
    border-radius: 15px 15px 0 0;
    position: relative;
    transition: all .5s ease;
    @include max-lg{
      background-size: cover;
    }
    .ai-category{
      font-size: 14px;
      display: block;
      position: absolute;
      background-color: $cyan;
      padding: .5rem 2rem;
      bottom: calc(-20px);
      border-radius: 0 15px 15px 0;
      z-index: 4;
      @include max-md{
        padding: .5rem 1rem;
      }
    }
  }
  .ai-post-details{
    padding: 3rem 2rem 1rem 2rem;
    text-align: left;
    border-left: 2px solid $cyan;
    border-bottom: 2px solid $cyan;
    border-right: 2px solid $cyan;
    border-radius: 0 0 15px 15px;
    position: relative;
    overflow: hidden;
    z-index: 2;
    min-height: 230px;
    background: radial-gradient(circle, #26DCFB 50%, transparent 50%);
    background-repeat: no-repeat;
    background-size: 250% 250%;
    background-position-x: 160%;
    background-position-y: 150px;
    transition: background-position .6s ease;

    h3{
      margin-bottom: .3rem;
    }
    span.ai-info{
      display: flex;
      align-items: center;
      margin: .3rem 0rem;
      font-size: 14px;
      &:last-child{
        margin-bottom: 0;
      }
      i{
        font-size: 28px;
        margin-right: 1rem;
      }
    }
    span.ai-post-date{
      font-size: 12px;
      display: flex;
      align-items: center;
      margin-top: 0rem;
      i{
        margin-right: .5rem;
      }
    }
    span.location{
      font-size: 14px;
      display: flex;
      align-items: center;
      i{
      }
    }
    span.ai-post-type{
      font-size: 14px;
      display: flex;
      align-items: center;
      i{

      }
    }
    @include max-md{
      padding: 3rem 1rem 1rem 1rem;
    }
  }
  &:hover{
    color: $blue;
    .ai-post-card-img{
      background-size: auto 120%;
    }
    .ai-post-details{
      background-position-x:center ;
      background-position-y: center;
    }
  }
  &.ai-product-card{
    .ai-post-details{
      min-height: unset ;
    }
  }
}

//Archive
.ai-posts-archive{
  .ai-post-card {
    margin-bottom: 3rem;
  }
}

.ai-post-card{
  &.ai-product-card{
    border: 2px solid $cyan;
    .ai-post-details{
      background: $white;
      border: none;
      padding-bottom: 2rem;
    }
  }
}

//Responsive
.ai-desktop-card{
  @include max-lg{
    display: none;
  }
}
.ai-mobile-posts-carousel{
  display: none;
  @include max-lg{
    display: block;
  }
  .slick-dots{
    bottom: 0 !important;
    li{
      button{
        &:before{
          color: rgba($cyan, 0.5) !important;
        }
      }
      &.slick-active{
        button{
          &:before{
            color: $cyan !important;
          }
        }
      }
    }
  }
}