.ai-whatsapp-icon{
  display: flex;
  justify-content: center;
  align-items: center;
  background: $green;
  border-radius: 100%;
  width: fit-content;
  height: fit-content;
  padding: 0.3rem;
  aspect-ratio: 1/1;
  flex-shrink: 0;
  overflow: hidden;
  position: relative;
  transition: transform 0.5s ease;
  svg{
    position: relative;
    z-index: 2;
  }
  &:after{
    content:'';
    position: absolute;
    left: -0.2rem;
    bottom: -0.2rem;
    height: 10px;
    width: 10px;
    background: $cyan;
    transition: transform .5s ease;
    border-radius: 100%;
    z-index: 0;
  }
  &:hover{
    transform: translateY(-5px), scale(1.01);
    svg{
      path{
        &:last-child{
        }
      }
    }
    &:after{
      transform: scale(18);
    }
  }
}

.ai-floating-whatsapp{
  height: fit-content;
  width: fit-content;
  position: fixed;
  left: auto;
  right: 1rem;
  top: 0;
  bottom: 0;
  margin: auto;
  .ai-whatsapp-icon{
    padding: 0.5rem;
  }
}